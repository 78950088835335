var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currency
    ? _c("span", { staticClass: "multiPrice" }, [
        _c("span", {
          staticClass: "multiPrice__price",
          domProps: { innerHTML: _vm._s(_vm.price_with_symbol) },
        }),
        _vm._v(" "),
        _vm.postfix.length
          ? _c("span", {
              staticClass: "multiPrice__postfix",
              domProps: { innerHTML: _vm._s(_vm.postfix) },
            })
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }