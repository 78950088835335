<template>

    <div class="productSizes">
        
        <template v-if="options.length > 0">

            <div class="productSize">


                <div class="multipleSelect" v-if="!singleSelect">

                    <div class="innerSelect">

                        <label
                                :for="'select_strength' + id"
                        >Pack Size</label>

                        <chosen
                                :options="activePackSizes"
                                v-model="selectedPackSize"
                                name="pack_size"
                                label="name"
                                trackBy="id"
                                placeholder=""
                                @input="updateStrengths()"
                                :id="'select_strength' + id"
                        ></chosen>

                    </div> <!-- .innerSelect -->

                    <div class="innerSelect">
                        
                        
                        <label
                                :for="'select_size' + id"
                        >
                            Strength
                            <tooltip 
                                    title="Strength Info" 
                                    :content="strengthTooltip"
                                    position="bottom"
                                    v-if="strengthTooltip"
                            />
                        </label>

                        <chosen
                                :options="activeStrengths"
                                v-model="selectedID"
                                name="strength"
                                label="strength"
                                trackBy="id"
                                placeholder=""
                                :id="'select_size' + id"
                        ></chosen>

                    </div>
                    
                </div> <!-- .multipleSelect -->

                <div class="singleSelect" v-else>
                    
                    <div class="innerSelect">
    
                        <label
                                v-if="selectLabel"
                                v-html="selectLabel"
                                :for="'select' + id"
                        ></label>
                        
                        <chosen
                                :options="options"
                                v-model="selectedID"
                                :name="'size_strength'"
                                label="display_value"
                                trackBy="id"
                                placeholder=""
                                :id="'select' + id"
                        ></chosen>
        
                    </div> <!-- .innerSelect -->

                </div> <!-- .singleSelect -->

                <input 
                        type="hidden" 
                        name="size" 
                        v-model="selectedID"
                >

            </div> <!-- .productSize -->
            
        </template>
        
        <template v-else>
            <input 
                    type="hidden" 
                    name="size" 
                    v-model="selectedID" 
                    @input="triggerChange()"
            >
        </template>
        
    </div> <!-- .productSizes -->

</template>

<script>

import collect from "collect.js";
import _ from 'lodash';

export default {
    name: 'productSizeSelect',
    props: {
        selectLabel: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        sizes: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        selectedSizeId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        },
        singleSelect: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        strengthTooltip: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        }
    },
    data() {
        return {
            selectedID: Number(0),
            selectedPackSize: 0,
            strengths: [],
            id: '',
            packSizes: []
        }
    },
    watch: {
        selectedID: {
            handler(val, was) {
                if(was !== 0) {
                  
                    this.triggerSizeChange(val);
                    this.triggerChange();
                    
                }
            }
        }
    },
    computed: {
        options() {
            return this.sortSizes();
        },
        activePackSizes() {
            return this.packSizes;
        },
        activeStrengths() {
            return this.strengths;
        }
    },
    created() {
        this.setDefault();
        this.triggerChange();
        this.setPackSize();
    },
    mounted() {

        this.id = this._uid;
        
        this.setDefault();
        this.triggerChange();
    },
    methods: {
        setDefault() {
            if(this.sizes) {
                if (this.sizes.length) {
                    const sizes = this.sortSizes();
                    
                    if(this.selectedSizeId) {
                        this.selectedID = parseInt(this.selectedSizeId);
                    }else{
                        this.selectedID = parseInt(sizes[0].id);
                    }
                    
                }
            }
        },
        
        setPackSize() {
            
            const used = [];

            const currentlySet = this.sizes.filter(size => size.id == this.selectedID);
            let currentName = '';
            
            if(currentlySet.length) {
                currentName = currentlySet[0].pack_size;
                this.selectedPackSize = currentlySet[0].id;
            }
            
            this.packSizes = this.sizes.map(size => {
                
                if(used.indexOf(size.pack_size) < 0) {
                    
                    let bypass = false;
                    
                    if(currentName.length && this.selectedPackSize > 0) {
                        
                        if(currentName == size.pack_size && size.id != this.selectedPackSize) {
                            bypass = true;
                        }
                        
                    }
                    
                    
                    if(!bypass) {
                        
                        const obj = {
                            id: size.id,
                            name: size.pack_size
                        };

                        used.push(size.pack_size);

                        return obj;
                        
                    }
                    
                }
                
                return false;
                
            }).filter(size => size);

            this.packSizes = _.sortBy(this.packSizes, obj =>  {
                return parseInt(obj.name, 10);
            });
            
            this.updateStrengths();
            
        },
        
        updateStrengths() {
            
            const selectedPackSizes = this.sizes.filter(size => {
                
                if(size.id == this.selectedPackSize) {
                    return size;
                }
                
            });
            
            const packSize = selectedPackSizes[0];

            this.strengths = this.sizes.filter(size => {
             
                if(typeof packSize.pack_size != 'undefined') {
                    if (size.pack_size == packSize.pack_size) {
                        return size;
                    }
                }
            });
            
            if(this.strengths.length) {
                this.selectedID = this.strengths[0].id;
            }
            
        },
        
        sortSizes() {
            const _this = this;

            let sizes = collect(this.sizes);

            sizes = sizes.map(size => {
                size.actual_price = _this.getPrice(size);
                size.display_price = _this.getCurrencySymbol() + _this.formatPrice(size.actual_price);
                
                let sale_text = '';

                if(parseFloat(size.actual_price) < parseFloat(size.price) && false) {
                    sale_text =  ' (was ' + _this.getCurrencySymbol() + _this.formatPrice(size.price) + ')';
                }
                
                size.display_value = size.size + ' - ' + size.display_price + sale_text;
                
                return size;
            }).filter(size => {
                
                if(size.hide != '1') {
                    return size;
                }
                
            });
            

            let sorted = sizes.sortBy('actual_price');

            return sorted.toArray();
        },
        
        getPrice(option) {
            
            let price = parseFloat(option.price),
                    salePrice = parseFloat(option.sale_price);
            
            let outputPrice = price;
           
            if(salePrice > 0 && salePrice < price) {
                outputPrice = salePrice;
            }
 
            return this.formatPrice(outputPrice);
            
        },
        
        formatPrice(price) {
            price = parseFloat(price);
            return price.toFixed(2);
        },
        
        getCurrencySymbol() {
            return '£';
        },
        
        triggerChange() {
            
            this.$emit('change', parseInt(this.selectedID));
        },
        
        triggerSizeChange(sizeID) {
            this.$emit('sizechanged', parseInt(sizeID));
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .productSizes {
        padding: 0 0 15px;
        
        .innerSelect {
            
            position: relative;
            
            & > label {
                display: flex;
                gap: 8px;
                flex-wrap: wrap;
                width: 100%;
            }
            
            .toolTip {
                
                .tip {
                    left: 50%;
                    transform: translateX(-50%);
                }
                
            }
            
            
        }
        
        .multipleSelect {
            display: flex;
            gap: 24px;
            
            @media all and (max-width: 500px) {
                flex-wrap: wrap;
                gap: 0;
            }
            
            .innerSelect {
                flex: 1 1 50%;

                @media all and (max-width: 500px) {
                    flex: 0 0 100%;
                    margin: 0 0 16px;
                    
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }

</style>
