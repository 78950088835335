<template>

    <div class="assessmentProgress" v-if="progress.visible">
        
        <div class="assessmentProgress__top">

            <div class="assessmentProgress__bar">

                <div 
                        class="assessmentProgress__barInner" 
                        :style="'width:' + progress.percentage + '%'"
                ></div>
                
            </div> <!-- .assessmentProgress__bar -->
            
        </div> <!-- .assessmentProgress__top -->

        <div class="assessmentProgress__bottom">
            <span>{{ progress.complete }}/{{ progress.total }}</span>
        </div> <!-- .assessmentProgress__bottom -->
        
    </div> <!-- .assessmentProgress -->

</template>

<script>
export default {
    name: 'progressBar',
    props: {
        progress: {
            type: [Object, Boolean],
            default: {
                percentage: 0,
                complete: 0,
                total: 0
            }
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .assessmentProgress {
        
        width: 100%;
        max-width: 434px;
        margin: 0 auto;
        padding: 24px 0 30px;
        
        &__top {
            
        }
        
        &__bottom {
            span {
                .rems(12);
                font-weight: 400;
                line-height: normal;
            }
        }
        
        &__bar {
            width: 100%;
            height: 6px;
            border-radius: 999px;
            overflow: hidden;
            background: #E2DFD4;
            position: relative;
        }

        &__barInner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            border-radius: 999px;
            background: @primary_color;
            transition: width 100ms linear;
        }
        
    }

</style>
