<template>

  <div
          id="assessmentContent"
          class="assessmentFormContent"
  >

      <template v-if="isFirstSection">
            
            <div class="previousAlert" v-if="previouslyCompleted">
                <div class="previousAlert__notice">
                    <p>
                        It appears you have already completed this assessment. <br>
                        Please check your answers and confirm the answers given
                        still apply to you. If not, please update your answers.
                    </p>
                </div> <!-- .alert alertBlue -->
            </div> <!-- .previousCompleted -->
        
            
            <div class="loginAlert" v-else>
        
                <div class="loginAlert__notice" v-if="showLoginLink && memberLoginLink">
        
                    <p>
                        Already a member and have completed this consultation before? <br>
                        <a :href="memberLoginLink">Click here</a> to login and load you're previous answers.
                    </p>
        
                </div> <!-- .alert alertBlue -->
        
            </div> <!-- .alertMessage -->
            
        </template>

      <progress-bar
              :progress="progress"
      />
    
      <form
              action=""
              enctype="multipart/form-data"
              method="post"
              class="formContentWrap"
              autocomplete="off"
              ref="mainForm"
              id="mainForm"
      >
    
          <template v-for="(section, sIndex) in sections">
    
    
              <form-section
                      :section="section"
                      :key="'section' + section.id"
                      :show-section-header="false"
                      :url-add-to-basket="urlAddToBasket"
                      @update-section="proceed"
                      @section-back="goBack"
                      v-if="section"
              />
    
          </template>
    
          <template v-if="$store.state.assessment.travel_form">
    
              <template v-for="multiuser in multiusers">
    
                  <input
                          type="hidden"
                          :name="'multiusers[' + multiuser.id + '][name]'"
                          :value="multiuser.name"
                          :key="'multiusername' + multiuser.id"
                  >
    
              </template>
    
          </template>
    
      </form> <!-- .formContentWrap -->

  </div>

</template>

<script>

import axios from 'axios';

import { mapGetters } from 'vuex';
import formSection from './form_section';
import progressBar from './progress_bar';

export default {
    name: "formContent",
    components: {
        formSection,
        progressBar
    },
    props: {
       showLoginLink: {
            type: Boolean,
            default: true,
        },
        urlGetMultiuserQuestions: {
            type: String,
            default: '/missing-url-get-multiuser-questions',
        },
        urlSubmitAssessment: {
            type: String,
            default: '/missing-url-submit-assessment',
        },
        urlGetAllowedProducts: {
            type: String,
            default: '/missing-url-get-allowed-products',
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
        categoryId: {
            type: Number,
            default: null,
        },
        productId: {
            type: Number,
            default: null,
        },
        previouslyCompleted: {
            type: Boolean,
            default: false,
        },
        showNotices: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loadingStartTimeout: null,
            loadingEndTimeout: null,
            hideSidebarAndInfo: true,
            sectionLoadingEnable: false
            
        }
    },
    computed: {
        ...mapGetters({
            sections: 'getAssessmentSections',
            multiusers: 'getAssessmentMultiusers',
            progress: 'getProgress'
        }),
        memberLoginLink() {
            return '/members/login.php?redirect=' + window.location;
        },
        isFirstSection() {

            let first = false;
            const sections = this.$store.state.assessment.sections;

            let x = 0;

            for(const prop in sections) {

                if(x === 0) {

                    const section = sections[prop];

                    if(section.visible) {
                        first = true;
                    }

                }

                x++;
            }

            return first;

        }
    },
    methods: {

        proceed(section) {

            const _this = this;


            if(section.can_proceed && section.submit_assessment) {

                this.submitAssessment(section);

            }else{

                if(section.product_selection) {

                    this.updatedSelectedProducts(this.$store.state.assessment.products);

                    if(section.can_proceed) {
                        this.nextSection();
                    }

                }else if(section.travel_section) {

                    if(section.can_proceed) {
                        this.getTravelQuestions(section);
                    }

                }else if(section.can_proceed) {
                    this.nextSection();
                }

            }

        },
        
        goBack() {
            
            this.previousSection();
            
        },

        getTravelQuestions: async function(section) {

            const _this = this;

            return new Promise((resolve, reject) => {

                this.$parent.setLoading(true, 'Loading questions');

                const user_question = section.travel_questions.filter(question => question.question_type == 'travel_users');

                let users = [];

                if(user_question.length) {
                    users = user_question[0].answer;
                }

                axios.post(_this.urlGetMultiuserQuestions, {
                    users: users,
                })
                .then(function (response) {

                    const data = response.data;
                    const errors = data.errors

                    if(data.ok) {

                        _this.$store.dispatch('setAssessmentMultiusers', data.multiuser_questions);

                        _this.nextSection();

                    }else{
                        //errors
                    }

                })
                .catch(function (error) {

                })
                .finally(function () {
                    section.loading = false;
                    resolve();
                });


            });
        },

        submitAssessment(section) {

            section.loading = true;
            this.$parent.setLoading(true, 'Saving assessment');

            const _this = this;

            const form = this.$refs.mainForm;
            const form_data = new FormData(form);

            form_data.set('assessment_type', this.$store.state.assessment.type);
            form_data.set('category_id', this.categoryId);
            form_data.set('product_id', this.productId);

            axios.post(this.urlSubmitAssessment, form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {

                const data = response.data;

                const errors = data.errors,
                    questionErrors = data.question_errors;

                if (data.ok) {
                    
                    
                    _this.$store.dispatch('setCreatedAssessments', data.created_assessments);
                    
                    //Get products if re-order is required
                    const reorderProducts = _this.buildReorderProducts();
                    
                    if(reorderProducts.length) {
                        _this.$store.state.assessment.individual_product = reorderProducts[0];
                    }
                    
                    //Send to basket
                    if(_this.$store.state.assessment.individual_product.id) {

                        _this.sendToBasket(section);
                        
                    }else {
                        axios.post(_this.urlGetAllowedProducts, {
                            assessment_ids: data.created_assessments,
                            category_id: _this.categoryId,
                            product_id: _this.productId,
                        })
                        .then(function (response) {
                            _this.nextSection();

                            //Used for testing recommended
                            //response.data[0][0].recommended = true;
                            
                            _this.$store.dispatch('setAssessmentProducts', response.data);
                        });
                    }
                    
                    
                }

            })
            .finally(function () {
                section.loading = false;
            });

        },
        
        buildReorderProducts() {
            
           
            const reorderData = this.$store.state.assessment.reorderData;
            let reorderProducts = [];
           
            if(reorderData.length) {
              
                reorderProducts = reorderData.map(product => {
                    
                    return {
                        id: product.product_id,
                        size: product.size_id,
                        quantity: product.quantity
                    }
                    
                });

                
            }

            return reorderProducts;
            
        },
        
        sendToBasket(section) {

            section.loading = true;

            const individualProduct = this.$store.state.assessment.individual_product;
            
            //Add assessment IDs
            const assessmentIDs = [];
            
            for(const prop in this.$store.state.createdAssessments) {
                const assessmentID = this.$store.state.createdAssessments[prop].id;
                assessmentIDs.push(assessmentID);
            }
            
            const form_data = new FormData();
            form_data.set('ajax', 'true');
            form_data.set('quantity', individualProduct.quantity);
            form_data.set('size', individualProduct.size);
            form_data.set('id', individualProduct.id);
            form_data.set('assessment_ids', assessmentIDs);
            form_data.set('add-bag', true);

            axios.post(this.urlAddToBasket, form_data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(function (response) {
                const data = response.data;
                section.loading = true;
                window.location = '/shop/checkout/basket.php';
            })
            .catch(function (error) {
                section.loading = true;
                window.location = '/shop/checkout/basket.php?error=failed_to_add_item';
            });

        },

        updatedSelectedProducts(products) {


            for(const userProp in products) {

                this.$store.dispatch('setAssessmentSelectedProducts', {
                    productProp: userProp,
                    products: products[userProp]
                });

            }


        },

        nextSection() {

            let sectionID = false,
                next = false;

            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];

                if(next) {
                    
                    if( !this.sectionIsEmpty(section) ) {
                        
                        sectionID = section.id;
                        next = false;

                        if (section.product_selection || section.product_confirmation) {
                            this.hideSidebarAndInfo = true;
                        }

                    }
                }

                if(section.visible) {
                    next = true;
                }

            }

            if(this.hideSidebarAndInfo) {
                this.$parent.showFormInfo = false;
            }else{
                this.$parent.showFormInfo = true;
            }
            
            if(sectionID) {
                scrollTo(0, 208);
                this.goToSection(sectionID);
            }

        },
        
        sectionIsEmpty(section) {
            
            if(!section.product_confirmation && !section.product_recommendations && !section.product_selection) {
                
                const visible = section.questions.filter(question => question.visible);
                
                if(visible.length < 1) {
                    return true;
                }
                
                
            }
            
            return false;
            
        },

        previousSection() {

            let sectionID = false,
                foundVisible = false;

            for(const prop in this.$store.state.assessment.sections) {

                let section = this.$store.state.assessment.sections[prop];

                if(section.visible) {
                    foundVisible = true;
                }

                if(!foundVisible) {
                    sectionID = section.id;
                }

            }

            if(sectionID) {
                this.goToSection(sectionID);
            }

        },

        goToSection(sectionID) {

            const _this = this;
            
            let section = this.sections.filter(section => section.id == sectionID);
            section = section[0];
            

            if(this.sectionLoadingEnable || section.product_confirmation || section.product_recommendations || section.product_selection) {
                
                this.setLoading(true, 'Loading next step');

                clearTimeout(this.loadingStartTimeout);
                clearTimeout(this.loadingEndTimeout);

                this.loadingStartTimeout = setTimeout(() => {

                    //assessmentContent
                    document.getElementById('assessmentContent').scrollIntoView({
                        behavior: 'smooth'
                    });

                    this.$store.dispatch('showAssessmentSection', sectionID);

                    this.loadingStartTimeout = setTimeout(() => {

                        this.setLoading(false);

                    }, 400);

                }, 100);

            }else{
                this.$store.dispatch('showAssessmentSection', sectionID);
            }


        },

        setLoading(loading, message) {
            this.$parent.setLoading(loading, message);
        }

    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .assessmentFormContent {
        grid-area: formarea;
        background: @assessment_content_background;
        display: block;
        max-width: 870px;
        
        padding: 40px 0 80px;
        position: relative;

        @media all and (max-width: 1020px) {
            width: 100%;
            flex: 0 0 100%;
        }
        
        @media @mobile {
            //text-align: center;
        }
        
        & > .alert {
            margin: 0 0 50px;
        }

        .formContentWrap {
            width: 100%;
            margin: 0 auto;
            display: block;
        }

        .previousCompleted {
            .alert {
                background: fade(@secondary_color, 40%) !important;
                color: @text_color !important;
            }
        }
        
        .loginAlert {
            
            display: flex;
            justify-content: center;
            margin: 0 0 24px;
            
            &__notice {
                
                flex: 0 0 100%;
                border-radius: @border_radius;
                padding: 16px 24px;
                background-color: @light_color;
                max-width: 640px;
                text-align: center;
                .rems(16);
                line-height: 24px;
                font-weight: 500;
                
                p {
                    width: 100%;
                    margin: 0;
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                    text-align: center;
                }
            }
        }

        .previousAlert {

            display: flex;
            justify-content: center;
            margin: 0 0 24px;

            &__notice {

                flex: 0 0 100%;
                border-radius: @border_radius;
                padding: 16px 24px;
                background-color: @light_color;
                max-width: 100%;
                text-align: center;
                .rems(16);
                line-height: 24px;
                font-weight: 500;
                background: @primary_color;

                p {
                    width: 100%;
                    margin: 0;
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                    text-align: center;
                    color: #fff;
                }
            }
            
        }

}



</style>
