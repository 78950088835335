<template>
    
  <main class="browsePage">

      <template v-if="page">


          <page-title
                  :title="page.title"
                  :content="page.title_content"
                  css-class="browseTitle"
          ></page-title>
          
          
      </template>

      <div class="wrapper">
          
            <product-filter 
                  :items="products" 
                  :filters="filters"
                  :loading="loading"
                  :follow-links="followLinks"
                  @update-ajax="updateAjax"
           ></product-filter>
    
          <product-list 
                  :loading="loading" 
                  :products="products" 
                  :parent-class="['browseProducts']" 
                  :list-class="['itemsBrowseGrid']"
          ></product-list>
          
          <pagination 
                  :pagination="pagination"
                  @update-ajax="updateAjax"
          ></pagination>
          
          <loading-spinner
                  :loading="loading"
                  loading-text="Loading Products"
          ></loading-spinner>
    
      </div> <!-- .wrapper -->
      
  </main>
    
</template>

<script>

import productList from './product_list';
import productFilter from './product_filter';
import pagination from './pagination';
import pageTitle from '../../pages/page_title';
import axios from 'axios';

export default {
    name: "BrowsePage",
    components: {
        productFilter,
        pagination,
        pageTitle,
        productList
    },
    props: {
        setProducts: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setFilters: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setPage: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        setPagination: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        }
    },
    data() {
        return {
            products: [],
            filters: [],
            page: [],
            pagination: [],
            previousURL: window.location.href,
            loading: false,
            useImageTitle: false
        }
    },
    created() {
        this.products = this.setProducts;
        this.filters = this.setFilters;
        this.page = this.setPage;
        this.pagination = this.setPagination;

    },
    mounted() {

        const _this = this;
        
        window.onpopstate = function(e){
            if(e.state){
                _this.updateAjax(window.location.href, true);
            }
        };

    },
    computed: {
        followLinks() {
            return !this.filters?.selections?.length;
        }
    },
    methods: {
        
        updateAjax(url, usedPopState) {
            
            if(typeof usedPopState == 'undefined') {
                usedPopState = false;
            }
            
            const _this = this;
            
            _this.loading = true;

            const title = document.getElementById('heroTitle');
            title.scrollIntoView({ behavior: 'smooth', block: 'start'});
            
            const urlWithAjax = this.enableAjaxURL(url);

            _this.previousURL = url;
            
            
            axios.get(urlWithAjax)
            .then(function (response) {
                
                const newData = response.data;


                _this.products = [];
                
                _this.$nextTick(function() {

                    _this.products = newData.products;
                    _this.filters = newData.filters;
                    _this.page = newData.page;
                    _this.pagination = newData.pagination;
                    
                    if(!usedPopState) {
                        window.history.pushState({url : url}, '', url);
                    }
                    
                    
                });
                
            })
            .catch(function (error) {
                //console.log(error);
            })
            .finally(function () {
                _this.loading = false;
            });
        },

        //Work around as htaccess is breaking post requests made to the page
        enableAjaxURL(url) {

            const parts = url.split('?');
            
            let query = '';
            
            if(parts.length > 1) {
                query = '?' + parts[1] + '&ajax_request=true';
            }else{
                query = '?ajax_request=true'
            }
            
            return parts[0] + query;
            
        },
        
        hasProducts(products) {
            
            if(typeof products != 'undefined') {
                return products.length;
            }
            
            return false;
            
        },
        
        relFollow(allowFollow) {
            
            if(!allowFollow) {
                return 'nofollow'
            }
            
            return '';
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .itemsBrowse {
        .itemsList();
    }
    
    .browsePage {
        position: relative;
        background: #fff;

        .itemsBrowseGrid {
            .itemsFlexList()
        }
        

        .loadingSpinner {
            position: fixed;
            top: 50vh;
            left: 50vw;
            transform: translateX(-50%, -50%);
            z-index: 5;
        }
        
        .titleBanner {
            
            padding: 170px 0 60px;
            background: @light_color;
            min-height: 106px;
            
            .innerContentWrapper {
                justify-content: center;
                text-align: center;
                
                .titleWrap {
                    justify-content: center;
                    text-align: center;
                    
                    .innerText {
                        margin: 0;
                    }
                }
            }
            
        }
        
    }
    
    .browseProducts {

        position: relative;
        transition: opacity 300ms;
        min-height: 300px;
        
        &.isLoading {
            opacity: 0.4;
            pointer-events: none;
            transition: opacity 300ms;
        }
        
    }
  


</style>
