import { render, staticRenderFns } from "./category_products.vue?vue&type=template&id=a443e4d2&"
import script from "./category_products.vue?vue&type=script&lang=js&"
export * from "./category_products.vue?vue&type=script&lang=js&"
import style0 from "./category_products.vue?vue&type=style&index=0&id=a443e4d2&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home3/stacure/public_sam2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a443e4d2')) {
      api.createRecord('a443e4d2', component.options)
    } else {
      api.reload('a443e4d2', component.options)
    }
    module.hot.accept("./category_products.vue?vue&type=template&id=a443e4d2&", function () {
      api.rerender('a443e4d2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "sites/default/js/components/shop/browse/category_products.vue"
export default component.exports