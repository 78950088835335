var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tagInput assessmentTags" },
    [
      _c("vue-tags-input", {
        attrs: {
          tags: _vm.tags,
          "autocomplete-items": _vm.autoCompleteValues,
          placeholder: _vm.placeholder,
        },
        on: {
          "tags-changed": (newTags) => _vm.updateAnswer(newTags),
          blur: function ($event) {
            return _vm.updateQuestion(_vm.question)
          },
        },
        model: {
          value: _vm.tag,
          callback: function ($$v) {
            _vm.tag = $$v
          },
          expression: "tag",
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.question.answer,
            expression: "question.answer",
          },
        ],
        attrs: {
          type: "hidden",
          name: _vm.questionName,
          id: "id_" + _vm.question.id,
          autocomplete: "off",
        },
        domProps: { value: _vm.question.answer },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.question, "answer", $event.target.value)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }