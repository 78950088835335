var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.progress.visible
    ? _c("div", { staticClass: "assessmentProgress" }, [
        _c("div", { staticClass: "assessmentProgress__top" }, [
          _c("div", { staticClass: "assessmentProgress__bar" }, [
            _c("div", {
              staticClass: "assessmentProgress__barInner",
              style: "width:" + _vm.progress.percentage + "%",
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "assessmentProgress__bottom" }, [
          _c("span", [
            _vm._v(
              _vm._s(_vm.progress.complete) + "/" + _vm._s(_vm.progress.total)
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }