<template>
    <span class="multiPrice" v-if="currency">
        <span v-html="price_with_symbol" class="multiPrice__price"></span>
        <span v-html="postfix" v-if="postfix.length" class="multiPrice__postfix"></span>
    </span>
</template>
<script>
import collect from "collect.js";
export default {
    props: {
        price: {
            type: [String, Number],
            default: 0
        },
        postfix: {
            type: [String, Boolean],
            default: ''
        }
    },
    data() {
        return {
            ratios: {},
            active_currency: 'GBP'
        }
    },
    mounted() {
        this.ratios = window.active_currencies;
        this.active_currency = window.active_currency;

        window.vueEventHub.$on('currency-change', event => {
            this.active_currency = window.active_currency;
        });
    },
    computed: {
        currency() {
            return collect(this.ratios)
                    .where('currency', this.active_currency)
                    .first();
        },
        price_in_currency() {
            return (parseFloat(this.currency.ratio) * this.price).toFixed(2);
        },
        price_with_symbol() {

            let returnData = this.currency.symbol + (parseFloat(this.currency.ratio) * this.price).toFixed(2);

            if ( this.postfix.length ) {
                let finalPrice = (parseFloat(this.currency.ratio) * this.price).toFixed(2);

                if ( finalPrice < 1 ) {
                    return ((finalPrice * 100).toFixed(0)) + 'p';
                }
            }

            return returnData;
        }
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .multiPrice {

        display: flex;
        align-items: flex-end;
        gap: 4px;


        &__postfix {
            .rems(15);
            font-weight: 400;
            line-height: 24px;
        }

    }

</style>
