<template>

    <div class="productSidebar">

        <div class="itemTitle">

            <h1
                    class="mainTitle"
                    v-if="product.name"
                    v-html="product.name"
            ></h1>

            <h3
                    class="itemSubtitle"
                    v-if="product.custom.product_subtitle"
                    v-html="product.custom.product_subtitle"
            ></h3>

            <div
                    class="productBadge"
                    v-if="productBadge.text.length && showBadge"
                    :class="[productBadge.class]"
                    v-html="productBadge.text"
            ></div> <!-- .productBadge -->


        </div> <!-- .itemTitle -->
        
        <template v-if="showStockBadge">
            
            <stock-badge
                    :has-stock="hasStock"
                    :small="false"
            ></stock-badge>

        </template>

        <div class="fromPrice" v-if="showPrice && price && fromTextEnabled">
            <span class="fromText">From</span>
            <multi-currency-price :price="price"/>
   
            <multi-currency-price
                    :price="pricePerTablet"
                    v-if="pricePerTablet > 0"
                    :postfix="' / ' + pillTYpe"
            />
        </div>
        
        <out-of-stock-notify
                v-if="!inStock"
                :url-submit="outOfStockUrl"
                button-label="Notify me when back in stock"
                :shop-item-id="product.id"
        ></out-of-stock-notify>

        <div 
                class="itemDescription wysiwyg" 
                v-if="product.description" 
                v-html="product.description"
        ></div>


        <add-to-basket
                :ajax-add-to-basket="$store.state.minibasket.productCardsQuickAdd"
                :basket-button-data="basketButtonData"
                :product="product"
                :default-size-selected-id="defaultSelectedSizeID"
                :show-price="true"
                :price-set="price"
                @sizeupdated="updateSize"
                @sizechanged="sizeChanged"
                :large-button="true"
                :single-size-select="false"
        ></add-to-basket>

        <delivery-countdown :cutoff="deliveryCutoff" />
        

        <div class="doctorWarnings" v-if="product.warnings?.length">

            <template v-for="warning in product.warnings">

                <div :class="warning.type" v-html="warning.content">

                </div> <!-- .alert -->

            </template>

        </div> <!-- .doctorWarnings -->

    </div> <!-- .productSidebar -->

</template>

<script>
import _ from 'lodash';
import deliveryCountdown from "./partials/delivery_countdown";
import addToBasket from "./partials/add_to_basket";
import outOfStockNotify from "../../plugins/mtc_outofstocknotify/out_of_stock_notify.vue";

export default {
    name: 'productSidebar',
    components: {
        addToBasket,
        outOfStockNotify,
        deliveryCountdown
    },
    props: {
        deliveryCutoff: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return 0;
            }
        },
        product: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        outOfStockUrl: {
            type: [String],
            default (rawProps) {
                return '/no-stock-url';
            }
        },
        fromPrice: {
            type: [Number, String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        hasFullDescription: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        inStock: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        productUrl: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        productImage: {
            type: [String, Boolean],
            default (rawProps) {
                return false;
            }
        },
        basketButtonData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {
                    label: false,
                    url: false
                };
            }
        },
        category: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        enableUrlUpdate: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        },
        showBadge: {
            type: [Boolean],
            default (rawProps) {
                return true;
            }
        }
    },
    
    data() {
        return {
            haseStock: true,
            price: 0,
            wasPrice: 0,
            showPrice: true,
            defaultSelectedSizeID: 0,
            showStockBadge: false,
            allowFromText: true,
            sizeChangeCount: -1
        }
    },
    
    computed: {
        fromTextEnabled() {
            return (this.product?.sizes?.length > 1 || this.product.product_type == 'doctor') && this.allowFromText;
        },
        pricePerTablet() {
            const cheapestSize = this.getLowestPriceSize();
            let returnPrice = 0;

            if(cheapestSize.hasOwnProperty('price_per_pill')) {
                returnPrice = parseFloat(cheapestSize.price_per_pill);
            }

            return returnPrice;
        },

        pillTYpe() {
            if (
                typeof this.product.custom.pill_type !== 'undefined' &&
                this.product.custom.pill_type.length
            ) {
                return this.product.custom.pill_type
            }
            return 'Tablet';
        },

        productBadge() {

            const badge = {
                class: '',
                text: ''
            }

            if(this.product?.custom?.product_badge?.length) {

                badge.text = this.product.custom.product_badge;

            }

            return badge;

        }
    },
    
    created() {

        this.setSizeFromURL();

        this.price = this.fromPrice;
        this.hasStock = this.inStock;

        if(!this.hasStock) {
            this.showPrice = false;
        }

        if(!this.basketButtonData.add_direct_to_basket) {
            this.allowFromText = true;
        }

    },
    
    methods: {

        getLowestPriceSize(returnPrice) {

            if (typeof this.product.sizes === "undefined" || this.product.sizes.length === 0) {
                return this.product.sale_price > 0 ?
                        this.product.sale_price :
                        this.product.price;
            }
            let minPrice = 0,
                    cheapestSize = false;

            this.product.sizes.forEach(size => {
                let sizePrice = size.sale_price > 0 ?
                        size.sale_price :
                        size.price;
                if (minPrice === 0) {
                    minPrice = sizePrice;
                    cheapestSize = size;
                } else if (sizePrice < minPrice) {
                    minPrice = sizePrice;
                    cheapestSize = size;
                }
            });

            if(typeof returnPrice != 'undefined') {
                if(returnPrice) {
                    return minPrice;
                }
            }

            return cheapestSize;
        },

        setSizeFromURL() {
            const searchParams = new URLSearchParams(window.location.search);

            if(searchParams.has('size')) {
                const sizeID = Number(searchParams.get('size'));
                this.defaultSelectedSizeID = sizeID;
                this.allowFromText = false;
            }

        },

        updateSize(size) {
            
            if(!_.isUndefined(size.id)) {

                this.price = parseFloat(size.actual_price);


                this.wasPrice = 0;
                this.salePrice = parseFloat(size.sale_price);

                if((this.salePrice > 0) && (this.salePrice < size.price) ) {
                    this.wasPrice = size.price;
                }

                let stock = parseInt(size.stock);

                if(isNaN(stock)) {
                    stock = 0;
                }

                this.hasStock = stock > 0;

                if(this.hasStock) {
                    this.showPrice = true;
                }else{
                    this.showPrice = false;
                }
                

                //Hide from text after changes by the user
                if(this.sizeChangeCount > 0) {
                    this.allowFromText = false;
                }

                this.sizeChangeCount++;
             

            }


        },

        sizeChanged(size) {
            this.updateSizeURL(size);
        },

        updateSizeURL(size) {

            if(!this.enableUrlUpdate) {
                return;
            }
            
            let sizeID = 0;

            if(!_.isUndefined(size.id)) {
                sizeID = size.id;
            }

            const url = new URL(window.location);
            const params = url.searchParams;
            const currentSize = params.get('size');

            if(!sizeID) {
                url.searchParams.set('size', false);
                window.history.pushState({}, "", url);
            }else if(currentSize != sizeID) {
                url.searchParams.set('size', sizeID);
                window.history.pushState({}, "", url);
            }

        }
    }
}
</script>

<style lang="less">

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/css/base/mixins.less';
@import '@site/css/base/font.less';


.productSidebar {

    --verticalSpacing: 27px;
    
    .doctorButtonInstructions {
        padding: 24px 12px 0 12px;
        font-weight: bold;

        @media @flyout {
            text-align: center;
        }

        p {
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            color: inherit;
            text-align: inherit;
            opacity: 0.8;
        }
    }


    .itemTitle {

        margin: 0;
        text-align: inherit;

        @media @product_breakpoint {
            text-align: center;
        }

        .mainTitle {
            margin: 0;
        }

        h1, h2  {
            .rems(42);
            line-height: normal;
            font-weight: 700;
            letter-spacing: normal;
            color: @primary_color;
            margin: 0;
            font-family: @primary_font;

            @media @product_breakpoint {
                .rems(28);
                line-height: 1.2em;
            }

        }

        .itemSubtitle {
            .rems(24);
            line-height: 32px;
            font-weight: 500;
            color: @primary_color;
            letter-spacing: normal;
            margin: 0;
            padding: 0 0 16px;
            font-family: @primary_font;


            @media @product_breakpoint {
                text-align: center;
            }

        }

        .productBadge {
            position: relative;
            padding: 10.5px 12px;
            background: @primary_color;
            color: #fff;
            .rems(16);
            font-weight: 600;
            line-height: 1em;
            border-radius: 100px;
            z-index: 3;
            width: auto;
            margin: 12px 0 0;
            display: inline-block;
        }


        .productBreadcrumbs {

            padding: 0 0 40px;

            @media @mobile {
                padding: 0 0 24px;
            }

            .breadcrumbs {

                &:before, &:after {
                    display: none;
                }

                display: flex;
                width: 100%;

                & > li {

                    display: none;
                    .rems(12);
                    line-height: 19px;
                    text-transform: uppercase;
                    padding: 0 16px 0 0;
                    position: relative;

                    a {
                        font-size: inherit;
                        line-height: inherit;
                        text-transform: inherit;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    &:before {
                        content: "/";
                        position: absolute;
                        left: ~"calc(100% - 9px)";
                        top: 0;
                        bottom: 0;
                        display: inline-block;
                        color: inherit;
                    }



                    &:nth-last-child(-n+2) {
                        display: flex;
                    }

                    &:last-child {
                        padding: 0;

                        &:before {
                            display: none;
                        }

                        a {
                            color: @text_color;
                            text-decoration: none;

                            &:hover, &:active {
                                color: @text_color;
                                text-decoration: none;
                            }
                        }

                    }
                }
            }

        }

    }

    .stockWrap {

        margin: 0;
        padding: 25px 0 0;
        text-align: inherit;
        width: 100%;

        @media @product_breakpoint {
            display: flex;
            justify-content: center;
            padding: 22px 0 0;
        }


    }

    .fromPrice {

        .rems(24);
        font-weight: 500;
        line-height: normal;
        letter-spacing: normal;
        margin: 0;
        padding-top: 12px;
        color: @primary_color;
        display: flex;
        flex-wrap: wrap;
        
        .fromText {
            flex: 0 0 100%;
            .rems(14);
            line-height: 14px;
        }

        .multiPrice {
            color: @primary_color;
            font-weight: 700;
            flex: 0 0 auto;

            & + .multiPrice {
                padding-left: 14px;
            }
        }

    }

    .itemDescription {

        margin: 0 0 -var(--verticalSpacing);
        padding: 13px 0 0;
        .rems(18);
        line-height: 29px;
        text-align: inherit;
        font-weight: 500;
        color: @text_color;

        @media @product_breakpoint {
            text-align: center;
        }

        p {
            margin-bottom: var(--verticalSpacing);
        }
       
        p, ul, ol {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            letter-spacing: 0;

            @media @product_breakpoint {
                font-size: inherit;
                line-height: inherit;
            }
        }

        ul, ol {

            margin: 0 0 var(--verticalSpacing) 29px !important;
            padding: 0 !important;
            .iconList();
            
            li {
                color: @primary_color;
                
                &:last-child {
                    margin-bottom: 0;
                }

            }

        }
    }
    
    .basketActions {

        position: relative;
        padding: 0;
        border-top: none;
        

        .row {
            float: none;
            clear: both;
        }
        
        .priceWrapper {
            flex: 1 1 100%;
            display: flex;
            align-items: center;
            padding: 0 16px 0 0;
            color: @primary_color;
            
            .multiPrice {
                .rems(24);
                font-weight: 700;
                line-height: normal;
            }
        }
        
        .row {
            border-bottom: none;
            padding: var(--verticalSpacing) 0 0;
            
            
            &:before, &:after {
                display: none;
            }
            
            .productSizes {
                padding: 0;
                display: flex;
                gap: 15px;
                width: 100%;
                
                .productSize {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 50%;
                }
                
                label {
                    color: #505D68;
                    margin: 0;
                    .rems(16);
                    font-weight: 500;
                    line-height: 16px;
                    width: 100%;
                    padding: 0 0 8px 0;
                }
                
                .innerSelect {
                    width: 100%;
                }
                
                .chosen-container {
                    width: 100%;
                    max-width: 247px;
                }
            }
        }
        
        .basketForm {
            
            & > .row {
                
                &:first-child {
                    padding-top: 0;
                }
                
            }
            
        }

        .basketButtonRow {
            
            @media @product_breakpoint {
                width: 100%;
            }
            
            &:before, &:after {
                display: none;
            }

            .rowInput {
                flex: 0 0 80px;

                .mNumberInput input {
                    min-height: 44px !important;
                    height: 44px !important;
                }
                
                & + .rowButton {
                    padding-left: 20px;
                }
            }

            .rowButton {
                
                flex: 1 1 auto;
                min-width: 200px;

                .addToBagButton {
                    text-align: center;
                    width: auto;
                    font-weight: 500;
                    padding: 12px 15px;
                    width: 100%;

                    @media @product_breakpoint {
                        width: 100%;
                    }
                }
            }
        }

        .coverCard {
            backdrop-filter: none !important;
        }
    }

    
    .deliveryCountdown {
        padding-top: var(--verticalSpacing);
    }
    
    .doctorWarnings {
        padding-top: var(--verticalSpacing);
    }


}

</style>
