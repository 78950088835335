var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productSizes" },
    [
      _vm.options.length > 0
        ? [
            _c("div", { staticClass: "productSize" }, [
              !_vm.singleSelect
                ? _c("div", { staticClass: "multipleSelect" }, [
                    _c(
                      "div",
                      { staticClass: "innerSelect" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "select_strength" + _vm.id } },
                          [_vm._v("Pack Size")]
                        ),
                        _vm._v(" "),
                        _c("chosen", {
                          attrs: {
                            options: _vm.activePackSizes,
                            name: "pack_size",
                            label: "name",
                            trackBy: "id",
                            placeholder: "",
                            id: "select_strength" + _vm.id,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.updateStrengths()
                            },
                          },
                          model: {
                            value: _vm.selectedPackSize,
                            callback: function ($$v) {
                              _vm.selectedPackSize = $$v
                            },
                            expression: "selectedPackSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "innerSelect" },
                      [
                        _c(
                          "label",
                          { attrs: { for: "select_size" + _vm.id } },
                          [
                            _vm._v(
                              "\n                        Strength\n                        "
                            ),
                            _vm.strengthTooltip
                              ? _c("tooltip", {
                                  attrs: {
                                    title: "Strength Info",
                                    content: _vm.strengthTooltip,
                                    position: "bottom",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("chosen", {
                          attrs: {
                            options: _vm.activeStrengths,
                            name: "strength",
                            label: "strength",
                            trackBy: "id",
                            placeholder: "",
                            id: "select_size" + _vm.id,
                          },
                          model: {
                            value: _vm.selectedID,
                            callback: function ($$v) {
                              _vm.selectedID = $$v
                            },
                            expression: "selectedID",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "singleSelect" }, [
                    _c(
                      "div",
                      { staticClass: "innerSelect" },
                      [
                        _vm.selectLabel
                          ? _c("label", {
                              attrs: { for: "select" + _vm.id },
                              domProps: { innerHTML: _vm._s(_vm.selectLabel) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("chosen", {
                          attrs: {
                            options: _vm.options,
                            name: "size_strength",
                            label: "display_value",
                            trackBy: "id",
                            placeholder: "",
                            id: "select" + _vm.id,
                          },
                          model: {
                            value: _vm.selectedID,
                            callback: function ($$v) {
                              _vm.selectedID = $$v
                            },
                            expression: "selectedID",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedID,
                    expression: "selectedID",
                  },
                ],
                attrs: { type: "hidden", name: "size" },
                domProps: { value: _vm.selectedID },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.selectedID = $event.target.value
                  },
                },
              }),
            ]),
          ]
        : [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedID,
                  expression: "selectedID",
                },
              ],
              attrs: { type: "hidden", name: "size" },
              domProps: { value: _vm.selectedID },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.selectedID = $event.target.value
                  },
                  function ($event) {
                    return _vm.triggerChange()
                  },
                ],
              },
            }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }