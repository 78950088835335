<template>
    <div class="membersProgressWrap">
        <div class="introBlock membersProgress horizontalIntro">
            <div class="innerIntro">
                <div>
                    <h3 class="introTitle" v-html="title"></h3>
                    <div class="introContentWrap" v-html="subtitle"></div>
                </div>
                <div class="buttonWrap">
                    <a :href="buttonLink + activeID" class="button buttonLight">
                        Log {{ activeName }} Progress
                    </a>
                </div>
            </div>
        </div>
        <div class="membersProgressTabs">
            <ul class="progressNav" v-if="categories.length > 1">
                <li v-for="(cat, index) in categories" :key="cat.id" :class="{'active' : isActivePane(index)}">
                    <a href="#" @click.prevent="activePane = index" v-html="cat.name"></a>
                </li>
            </ul> <!-- .progressNav -->
            <div class="progressPane" v-for="(cat, index) in categories" :key="cat.id" v-show="activePane === index">
                <treatment-tracker
                    :progress="cat.logs"
                    :category="cat"
                    :tracker-type="(cat.label!== ''&& cat.label !== null) ? 'graph' : 'list'"
                    :member-name="memberName"
                ></treatment-tracker>
            </div> <!-- .progressPane -->
        </div> <!-- .membersProgressTabs -->
    </div> <!-- .membersProgressWrap -->
</template>

<script>
export default {
    name: "ProgressTabs",
    props: {
        title: {
            type: String,
            default(rawProps) {
                return 'Your Progress';
            }
        },
        subtitle: {
            type: String,
            default(rawProps) {
                return 'Track your progress here.';
            }
        },
        buttonLink: {
            type: String,
            default(rawProps) {
                return '/members/progress_tracker/edit.php?category_id=';
            }
        },
        categories: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return '';
            }
        },
        memberName: {
            type: String,
            default (rawProps) {
                return '';
            }
        }
    },
    data() {
        return {
            activePane: 0,
            loading: false
        }
    },
    created() {

    },
    mounted() {
        this.loading = true;

        this.loading = false;
    },
    computed: {
        activeID () {
            return this.categories[this.activePane].id
        },
        activeName () {
            return this.categories[this.activePane].name
        }
    },
    methods: {
        isActivePane(index) {
            return (this.activePane === index);
        }
    }
}
</script>

<style lang="less" scoped>

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';

@primary_gradient: linear-gradient(109deg, var(--secondaryGradientStart, #E97E42) 0%, var(--secondaryGradientEnd, #D25712) 100%);;
@button_color: @secondary_color;
@button_color_hover: darken(@button_color, 8%);
@button_color_focus: @primary_gradient;

@button_top_padding: 15px;
@button_side_padding: 15px;
@button_bottom_padding: 13px;


@button_border_radius: 50px;

.membersProgressTabs {
    .progressNav {
        display: flex;
        flex-flow: row nowrap;
        gap: 12px;
        margin: 0 0 12px;

        li {
            a {
                display: inline-block;
                background: @button_color;
                color: @text_color;
                border: none;
                padding: @button_top_padding @button_side_padding @button_bottom_padding;
                cursor: pointer;
                text-align: center;
                outline: none;
                font-weight: 400;
                .rems(12);
                line-height: 12px;
                transition: all 200ms linear;
                border-radius: @button_border_radius;
                text-decoration: none;
                box-shadow: none;
                z-index: 1;
                overflow: hidden;
                text-transform: unset;
                letter-spacing: 0.24px;

                &:hover, &:focus {
                    background: @button_color_hover;
                }

                &:active {
                    color: #fff;
                    background: @button_color_focus;
                }
            }

            &.active {
                a {
                    color: #fff;
                    background: @button_color_focus;
                }
            }
        }
    }
}

</style>
