<template>

    <div class="assessmentPageWrapper">
        
        <div class="wrapper">
            
            <main
                    class="assessmentForm"
                    :class="[loading ? 'isLoading' : '', showFormInfo ? '' : 'fullWidthContent']"
            >
    
    
                <form-content
                        :url-get-multiuser-questions="urlGetMultiuserQuestions"
                        :url-submit-assessment="urlSubmitAssessment"
                        :url-get-allowed-products="urlGetAllowedProducts"
                        :url-add-to-basket="urlAddToBasket"
                        :form-id="form.id"
                        :loading="loading"
                        :category-id="categoryId"
                        :product-id="productId"
                        :previously-completed="form.previously_complete"
                        :showNotices="false"
                        :show-login-link="showLoginLink"
                />
    
                <form-sidebar-info v-if="showFormInfo"/>
    
                <div class="loading">
    
                    <loading-spinner
                            :loading="loading"
                            :loading-text="''"
                            :show-loading-text="true"
                    ></loading-spinner>
    
                </div> <!-- .loading -->
    
    
            </main>

        </div> <!-- .wrapper -->
        
        
        <slot></slot>
        
    </div> <!-- .assessmentPageWrapper -->

    
</template>

<script>

import formSidebarInfo from './elements/form_sidebar_info';
import formContent from './elements/form_content';
import pageTitle from '../pages/page_title';

export default {
    
    name: 'assessmentForm',
    
    components: {
        formSidebarInfo,
        formContent,
        pageTitle
    },
    
    props: {
        reorderData: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return false;
            }
        },
        setForm: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return {};
            }
        },
        formId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return 0;
            }
        },
        setSections: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        userSurgery: {
            type: [Object],
            default (rawProps) {
                return {
                    practice_name: '',
                    address_1: '',
                    address_2: '',
                    city: '',
                    postcode: '',
                    phone: '',
                    id: ''
                };
            }
        },
        userHealthProfile: {
            type: [Object, Array],
            default (rawProps) {
                return {
                    medications: '',
                    allergies: '',
                    conditions: ''
                };
            }
        },
        medicalConditionList: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        medicationsList: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        urlGetMultiuserQuestions: {
            type: String,
            default: '/missing-url-get-multiuser-questions',
        },
        urlSubmitAssessment: {
            type: String,
            default: '/missing-url-submit-assessment',
        },
        urlGetAllowedProducts: {
            type: String,
            default: '/missing-url-get-allowed-products',
        },
        urlAddToBasket: {
            type: String,
            default: '/missing-url-add-to-basket',
        },
        categoryId: {
            type: Number,
            default: null,
        },
        productId: {
            type: Number,
            default: null,
        },
        productSizeId: {
            type: Number,
            default: null,
        },
        productQuantity: {
            type: Number,
            default: null,
        },
        loadFromStorage: {
            type: Boolean,
            default: true,
        },
        showLoginLink: {
            type: Boolean,
            default: true,
        }
    },
    
    data() {
        return {
            loading: false,
            form: {},
            defaultLoadingMessage: 'Loading',
            loadingMessage: 'Loading',
            showFormInfo: false
        }
    },
    
    created() {
        
        this.form = this.setForm;
        
        //Set reorder data
        this.$store.dispatch('setReorderData', this.reorderData);
        
        //Load answers from localstorage
        this.$store.dispatch('setLoadFromStorage', this.loadFromStorage);

        //Store form id
        this.$store.dispatch('setAssessmentID', this.formId);

        //Store user data
        this.$store.dispatch('setUserSurgery', this.userSurgery);

        //Store health profile
        this.$store.dispatch('setUserHealthProfile', this.userHealthProfile);

        //Store list of medications for predictive inputs
        this.$store.dispatch('setMedicationsList', this.medicationsList);

        //Store list of conditions for predictive inputs
        this.$store.dispatch('setMedicalConditionList', this.medicalConditionList);

        //this.$store.dispatch('setAssessmentType', this.form.form_type);
        this.$store.dispatch('setAssessmentProductID', this.productId);
        this.$store.dispatch('setAssessmentCategoryID', this.form.category_id);
        this.$store.dispatch('setAssessmentTravelForm', this.form.travel_form);

        //Store individual product
        this.$store.dispatch('setAssessmentIndividualProduct', {
            id: this.productId,
            size: this.productSizeId,
            quantity: this.productQuantity
        });


        let previousForm = localStorage.getItem('assessment_sections_' + this.formId);

        this.$store.dispatch('setAssessmentSection', this.setSections);
        
        //Load previous forms
        if(previousForm) {
            this.$store.dispatch('reloadAssessment', this.formId);
        }
        
        
        //Begin progress
        this.$store.dispatch('updateProgress', true);
        
        
    },
    
    mounted() {
        EventBus.$emit('assessmentStep', 1);
    },
 
    methods: {
        setLoading(loading, message) {
            
            if(_.isUndefined(message)) {
                message = this.defaultLoadingMessage;
            }
            
            this.loadingMessage = message;
            this.loading = loading;
        }
    }
    
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .assessmentPageWrapper {
        
        
        .assessmentServiceFooter {

            position: relative;
            float: none;
            clear: both;
            width: 100%;
            background: @footer_bg_color;
            padding: 148px 0 82px;
            overflow: hidden;
            margin-top: -7px;
            z-index: 10;

            @media @mobile {
                padding: 112px 0 80px;
            }
            
            & > .wrapper {
                position: relative;
                z-index: 2;
            }

            .mask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                z-index: 1;

                svg {
                    width: 100%;
                    height: auto;
                }
            }

            .introBlock {
                
                margin-bottom: 48px;
                
                .introTitle {
                    .rems(41);
                    font-weight: 500;
                    line-height: 39.308px;
                    letter-spacing: -0.244px;
                    
                    @media @mobile {
                        .rems(24);
                        line-height: 32px;
                    }
                }
                
            }
            
            .serviceList {
                
                &__title {
                    .rems(25);
                    font-weight: 500;
                    line-height: 28.308px;
                    letter-spacing: -0.244px;
                    
                    @media @flyout {
                        .rems(20);
                        line-height: 30px;
                        letter-spacing: normal;
                    }
                    
                    br {
                        @media @flyout {
                            display: none;
                        }
                    }
                }
                
                &__description {
                    br {
                        @media @flyout {
                            display: none;
                        }
                    }
                }
            }
            
        }
        
    }
    
    .assessmentForm {
        display: grid;
        grid-gap: 0;
        grid-template-columns: 3fr 284px;
        grid-template-areas:  "formarea sidebarinfo";
        //min-height: 80vh;
        color: @text_color;
        position: relative;
        width: 100%;
     

        @media all and (max-width: 1220px) {
            grid-template-columns: 3fr 284px;
        }

        @media all and (max-width: 1020px) {
            display: flex;
            flex-wrap: wrap;
        }
        
        .row {
            float: none;
            clear: both;
        }
        
        &.fullWidthContent {
            display: flex;
            justify-content: center;
            
            .assessmentFormContent {
                width: 100%;
                flex: 0 0 100%;
                max-width: 1092px;
                
            }
        }

        .loading {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
            background: rgba(255,255,255,1);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 200ms linear, visibility 200ms linear;

            .loadingSpinner {
                position: fixed;
                top: 50vh;
                left: 50vw;
                transform: translate(-50%, -50%);
                z-index: 10;
                display: none;
                margin-top: -20vh;
            }
            
            .loadingText {
                margin-top: -24px;
                display: none;
            }
        }

        
        &.isLoading {

            .loading {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                transition: opacity 200ms linear, visibility 200ms linear;

                .loadingSpinner {
                    display: inline-block;
                }
            }

        }
    }
    

</style>
