var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.section.visible,
          expression: "section.visible",
        },
      ],
      staticClass: "assessmentSection",
    },
    [
      _vm.showSectionHeader
        ? _c("header", { staticClass: "sectionHeader" }, [
            _c("h2", {
              staticClass: "sectionTitle",
              domProps: { innerHTML: _vm._s(_vm.section.title) },
            }),
          ])
        : _vm.section.travel_section
        ? [
            _vm._l(_vm.section.travel_questions, function (question) {
              return [
                _vm.isQuestion(question)
                  ? _c("question-wrap", {
                      key: "qwrap" + question.id,
                      ref: "travelQuestionWrap",
                      refInFor: true,
                      attrs: {
                        "question-id": question.id,
                        "question-name": question.name,
                        "travel-question": true,
                      },
                    })
                  : _vm._e(),
              ]
            }),
          ]
        : _vm.section.multiuser_section
        ? [
            _vm._l(_vm.assessmentMultiusers, function (multiuser, userIndex) {
              return [
                _c(
                  "collapse-area",
                  {
                    attrs: {
                      title: _vm.section.title + " for " + multiuser.name,
                      "section-id": "user_group_questions_" + multiuser.id,
                      open: userIndex < 1,
                      "has-error": !multiuser.can_proceed,
                    },
                  },
                  [
                    _vm._l(multiuser.questions, function (question) {
                      return [
                        _vm.isQuestion(question)
                          ? _c("question-wrap", {
                              key: "travel_qwrap" + question.id,
                              ref: "multiUserQuestionWrap",
                              refInFor: true,
                              attrs: {
                                "multiuser-question": true,
                                "multiuser-id": multiuser.id,
                                "question-id": question.id,
                                "question-name": question.name,
                                "travel-question": true,
                              },
                              on: { "update-errors": _vm.updateMultiuserError },
                            })
                          : _vm.isWarning(question)
                          ? _c("warning-wrap", {
                              key: "travel_wwrap" + question.id,
                              attrs: {
                                "multiuser-question": true,
                                "multiuser-id": multiuser.id,
                                "question-id": question.id,
                              },
                            })
                          : _vm.isHTML(question)
                          ? _c("markup-wrap", {
                              key: "travel_mwrap" + question.id,
                              attrs: {
                                "multiuser-question": true,
                                "multiuser-id": multiuser.id,
                                "question-id": question.id,
                              },
                            })
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                ),
              ]
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.section.product_selection
        ? [
            _vm.$store.state.assessment.travel_form
              ? [
                  _vm.productGroups[_vm.section.multiuser_id]
                    ? _c("product-listings", {
                        attrs: {
                          selectionEnabled: true,
                          products: _vm.productGroups[_vm.section.multiuser_id],
                          "multiuser-id": _vm.section.multiuser_id,
                        },
                        on: { "option-changed": _vm.validate },
                      })
                    : _vm._e(),
                ]
              : [
                  _vm.productGroups[0]
                    ? _c("product-listings", {
                        attrs: {
                          selectionEnabled: true,
                          products: _vm.productGroups[0],
                          "multiuser-id": 0,
                        },
                        on: { "option-changed": _vm.validate },
                      })
                    : _vm._e(),
                ],
          ]
        : _vm.section.product_confirmation
        ? [
            _vm._l(_vm.selectedProductGroups, function (products, multiuserID) {
              return [
                _c("product-listings", {
                  attrs: {
                    selectionEnabled: false,
                    products: products,
                    "multiuser-id": multiuserID,
                  },
                  on: { "option-changed": _vm.validate },
                }),
              ]
            }),
          ]
        : [
            _vm._l(_vm.section.questions, function (question, index) {
              return [
                _vm.isQuestion(question)
                  ? _c("question-wrap", {
                      key: "qwrap" + question.id,
                      ref: "questionWrap",
                      refInFor: true,
                      attrs: {
                        "question-id": question.id,
                        "question-number": question.number,
                      },
                      on: { "direct-proceed": _vm.updateSection },
                    })
                  : _vm._e(),
                _vm._v(" "),
                question.custom_error?.length
                  ? _c("warning-wrap", {
                      attrs: {
                        "question-id": 0,
                        "custom-error": question.custom_error,
                      },
                    })
                  : _vm.isWarning(question)
                  ? _c("warning-wrap", {
                      key: "wwrap" + question.id,
                      attrs: { "question-id": question.id },
                    })
                  : _vm.isHTML(question)
                  ? _c("markup-wrap", {
                      key: "mwrap" + question.id,
                      attrs: { "question-id": question.id },
                    })
                  : _vm._e(),
              ]
            }),
          ],
      _vm._v(" "),
      _c(
        "footer",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.footerVisible,
              expression: "footerVisible",
            },
          ],
          staticClass: "sectionFooter",
        },
        [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.section.order > 1 && _vm.backButtonEnabled,
                  expression: "section.order > 1 && backButtonEnabled",
                },
              ],
              staticClass: "button",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.sectionBack()
                },
              },
            },
            [_vm._v("Back")]
          ),
          _vm._v(" "),
          !_vm.section.product_selection
            ? _c("button", {
                staticClass: "button",
                attrs: { type: "button", disabled: _vm.proceedButtonDisabled },
                domProps: { innerHTML: _vm._s(_vm.proceedButtonText) },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.updateSection()
                  },
                },
              })
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }