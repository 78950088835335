<template>

    <div class="tagInput">
        
        <vue-tags-input
                v-model="tag"
                :tags="tags"
                @tags-changed="newTags => updateTags(newTags)"
                :placeholder="inputPlaceholder"
                :autocomplete-items="autocompleteObjects"
        />

        <input 
               type="hidden"
               v-model="tagString"
               :name="inputName"
        />
        
    </div> <!-- .tagitField -->
    
</template>

<script>

export default {

    name: 'mtcTagInput',
    props: {
        inputPlaceholder: {
            type: [String],
            default (rawProps) {
                return 'Add tag';
            }
        },
        inputName: {
            type: [String],
            default (rawProps) {
                return 'tags';
            }
        },
        autocompleteItems: {
            type: [Array, Object],
            default (rawProps) {
                return [];
            }
        },
        tagsString: {
            type: [String, Boolean],
            default (rawProps) {
                return '';
            }
        }
    },
    computed: {
        autocompleteObjects() {
            
            let ac = [];
            
            if(this.autocompleteItems.length) {
                
                this.autocompleteItems.forEach(item => {
                    ac.push({
                        text: item
                    });
                });

            }
            if(ac.length < 1) {
                return ac;
            }

            return ac.filter(i => {
                return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
            });
           
        
        }
    },
    data() {
        return {
            tag: '',
            tags: [],
            tagString: '',
            autoCompleteArray: []
        }
    },
    created() {
        this.tagString = this.tagsString;
        this.tags = this.stringToTags(this.tagString);
    },
    methods: {

        updateTags(newTags) {
            
            let str = '';

            newTags.forEach((tag, i) => {
                
                if(i > 0) {
                    str += ',';
                }
                
                str += tag.text;
                
            });
            
            this.tags = newTags;
            this.tagString = str;
        },
        
        stringToTags(inputString) {
            
            if(typeof inputString == 'undefined') {
                return;
            }
            
            if(!inputString.length) {
                inputString = this.defaultTags;
            }
            
            let tags = [];
            
            if(inputString.length) {
                let splitTags = inputString.split(',');
                
                splitTags.forEach(tag => {

                    tags.push({
                        text: tag
                    });
                    
                });
            }
            
            return tags;
            
        },
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';
    
    .ti-input {
        
        .ti-tags {
            border-radius: 59px;
        }
        
    }

    .tagInput .vue-tags-input .ti-autocomplete {
        
        top: 100%;
        margin-top: 6px;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid #E2DFD4;
        box-shadow: 0 8px 8px rgba(0,0,0,0.08);
        
        &:before {
            display: none;
        }
        
        ul {
            overflow: auto;
            max-height: 150px;
            height: auto;
        }
    }


</style>
