<template>
    <div class="progressTrackingWrap" v-cloak>
        <div class="progressTracking" :class="[ trackerType ]">
            <template v-if="trackerType === 'graph'">
                <div class="progressEntriesWrap">
                    <div class="entriesHead">
                        <h4>{{ category.name }}</h4>
                        <a :href="'/members/progress_tracker/?category_id=' + category.id"
                           class="button buttonOutlined">
                            View all entries
                        </a>
                    </div>
                    <div class="entriesBody">
                        <div class="progressEntry" v-for="(entry, index) in progressData.slice(0, 4)" :key="'entry-' + entry.id">
                            <a :href="'/members/progress_tracker/edit.php?category_id=' + entry.cat + '&id=' + entry.id">
                                <div class="progressContent">
                                    <i class="svgIcon" v-if="Math.sign(entry.diff) === 1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 11" fill="none">
                                            <path d="M4.99376 5.16015e-07L10 4.90111L10 6.74667L5.66792 2.50556L5.66791 11L4.33208 11L4.33209 2.49333L-5.80768e-07 6.73444L-4.19424e-07 4.88889L4.99376 5.16015e-07Z" fill="currentColor"/>
                                        </svg>
                                    </i>
                                    <i class="svgIcon" v-else-if="Math.sign(entry.diff) === 0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 2" fill="none">
                                            <path d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z" fill="currentColor"/>
                                        </svg>
                                    </i>
                                    <i class="svgIcon" v-else-if="Math.sign(entry.diff) === -1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 11" fill="none">
                                            <path d="M5.00624 11L0 6.09889L0 4.25333L4.33209 8.49444L4.33209 0L5.66792 0L5.66792 8.50667L10 4.26556V6.11111L5.00624 11Z" fill="currentColor"/>
                                        </svg>
                                    </i>
                                    <span class="progressWeight">
                                        {{ entry.value }}kg
                                    </span>
                                    <span class="progressDiff">
                                        ({{ entry.diff }}%)
                                    </span>
                                    <span class="progressDate">
                                        {{ entry.date }}
                                    </span>
                                </div> <!-- .progressContent -->
                                <div class="progressImage">
                                    <img v-if="typeof entry.image !== 'undefined' && entry.image !== null"
                                         :src="'/uploads/images/progress_items/' + entry.image"
                                         :alt="memberName + '\'s ' + category.name + ' Update ' + entry.date"
                                    />
                                    <img v-else
                                         src="/sites/default/images/no-image.jpg"
                                         :alt="memberName + '\'s ' + category.name + ' Update ' + entry.date"
                                    />
                                </div> <!-- .progressImage -->
                            </a>
                        </div>
                    </div>
                </div>
                <div class="progressGraph">
                    <div class="chartFilter">
                        <ul>
                            <li v-for="day in chart.filter">
                                <a :href="'#' + day"
                                   :class="{ active: filterActive(day) }"
                                   @click.prevent="chart.days = day"
                                   v-html="'Last ' + day + ' days'"
                                ></a>
                            </li>
                            <li>
                                <a href="#365"
                                   :class="{ active: filterActive(365) }"
                                   @click.prevent="chart.days = 365">
                                    Last year
                                </a>
                            </li>
                        </ul>
                    </div> <!-- .chartFilter -->
                    <div class="chartWrapper">
                        <LineChart :data="chartData"
                                   :options="chart.options"
                                   :style="myStyles"
                        />
                    </div>
                </div>
            </template>
            <template v-else-if="trackerType === 'list'">
                <div class="progressEntriesWrap">
                    <div class="entriesHead">
                        <h4>{{ category.name }}</h4>
                        <a :href="'/members/progress_tracker/?category_id=' + category.id"
                           class="button buttonOutlined">
                            View all entries
                            <i class="svgIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 9" fill="none">
                                    <path d="M10 4.49438L5.54444 9L3.86667 9L7.72222 5.10112L4.64936e-08 5.10112L6.08303e-08 3.89888L7.73333 3.89888L3.87778 4.6242e-08L5.55556 6.62493e-08L10 4.49438Z" fill="currentColor"/>
                                </svg>
                            </i>
                        </a>
                    </div>
                    <div class="entriesBody">
                        <div class="progressEntry" v-for="(entry, index) in progressData.slice(0, 5)" :key="'entry-' + entry.id">
                            <a :href="'/members/progress_tracker/edit.php?category_id=' + entry.cat + '&id=' + entry.id">
                                <div class="progressContent">
                                    <i class="svgIcon" v-if="entry.rating === '0'">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                                            <path d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"/>
                                        </svg>
                                    </i>
                                    <i class="svgIcon" v-else-if="entry.rating === '2'">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 2" fill="none">
                                            <path d="M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z" fill="currentColor"/>
                                        </svg>
                                    </i>
                                    <i class="svgIcon" v-else-if="entry.rating === '1'">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
                                            <path d="M0 56v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56zm40 200c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm272 256c-20.183 0-29.485-39.293-33.931-57.795-5.206-21.666-10.589-44.07-25.393-58.902-32.469-32.524-49.503-73.967-89.117-113.111a11.98 11.98 0 0 1-3.558-8.521V59.901c0-6.541 5.243-11.878 11.783-11.998 15.831-.29 36.694-9.079 52.651-16.178C256.189 17.598 295.709.017 343.995 0h2.844c42.777 0 93.363.413 113.774 29.737 8.392 12.057 10.446 27.034 6.148 44.632 16.312 17.053 25.063 48.863 16.382 74.757 17.544 23.432 19.143 56.132 9.308 79.469l.11.11c11.893 11.949 19.523 31.259 19.439 49.197-.156 30.352-26.157 58.098-59.553 58.098H350.723C358.03 364.34 384 388.132 384 430.548 384 504 336 512 312 512z"/>
                                        </svg>
                                    </i>
                                    <span class="progressRating">
                                        {{ rating[entry.rating] }}
                                    </span>
                                    <span class="progressDate">
                                        {{ entry.date }}
                                    </span>
                                </div> <!-- .progressContent -->
                                <div class="progressImage">
                                    <img v-if="typeof entry.image !== 'undefined' && entry.image !== null"
                                         :src="'/uploads/images/progress_items/' + entry.image"
                                         :alt="memberName + '\'s ' + category.name + ' Update ' + entry.date"
                                    />
                                    <img v-else
                                         src="/sites/default/images/no-image.jpg"
                                         :alt="memberName + '\'s ' + category.name + ' Update ' + entry.date"
                                    />
                                </div> <!-- .progressImage -->
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    LineElement,
    PointElement,
    Filler,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { Line as LineChart } from 'vue-chartjs'

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Filler, Title, Tooltip)

export default {
    name: "TreatmentTracker",
    props: {
        progress: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        category: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return '';
            }
        },
        trackerType: {
            type: String,
            default (rawProps) {
                return 'graph';
            }
        },
        memberName: {
            type: String,
            default (rawProps) {
                return '';
            }
        }
    },
    components: { LineChart },
    data() {
        return {
            loading : {
                data: false,
                graph: false
            },
            progressData: [],
            chart: {
                days: 30,
                filter: [7, 30, 90, 180],
                options: {
                    plugins: {
                        tooltip: {
                            backgroundColor: 'rgba(54, 54, 54, 0.7)',
                            displayColors: false,
                            padding: 10
                        }
                    },
                    scales: {
                        y: {
                            type: 'linear',
                            ticks: {
                                beginAtZero: false,
                                stepSize: 5,
                            },
                        },
                        x: {
                            grid: {
                                display: false
                            }
                        }
                    }
                }
            },
            rating: ['Good', 'Bad', 'Neutral'],
            active_tracker: 0,
        }
    },

    created() {

    },
    mounted() {
        this.loading.data = true;
        if (this.trackerType === 'graph') {
            this.loading.graph = true;
            this.progressData = this.progress.map((e, index) => {
                let date = new Date(e.date);
                return {
                    id: e.id,
                    cat: e.progress_category_id,
                    value: e.input_value,
                    diff: this.calcDifference(index),
                    image: e.image,
                    timestamp: e.date,
                    date: date.toLocaleDateString('en-GB'),
                    date_short: date.getDate() + '/' + date.getMonth(),
                }
            });
            this.loading.graph = true;
        } else {
            this.progressData = this.progress.map((e, index) => {
                let date = new Date(e.date);
                return {
                    id: e.id,
                    cat: e.progress_category_id,
                    rating: e.rate,
                    image: e.image,
                    timestamp: e.date,
                    date: date.toLocaleDateString('en-GB'),
                }
            });
        }
        this.loading.data = false;
    },
    computed: {
        myStyles () {
            return {
                // height: `${/* mutable height */}px`,
                position: 'relative'
            }
        },
        chartData () {
            if (this.trackerType === 'graph') {
                return {
                    labels: this.filteredLabels,
                    datasets: [{
                        label: this.category.label,
                        data: this.filteredData,
                        fill: 'origin',
                        backgroundColor: 'rgba(157, 152, 174, 0.2)',
                        borderColor: '#9D98AE',
                        pointBackgroundColor: '#D9776D',
                        pointBorderColor: '#ffffff',
                        pointBorderWidth: 3,
                        pointRadius: 4,
                        pointHoverBackgroundColor: '#D9776D',
                        pointHoverBorderColor: '#ffffff',
                        pointHoverBorderWidth: 1,
                        pointHoverRadius: 6,
                        tension: 0.5
                    }],
                }
            } else {
                return false;
            }
        },
        filteredLabels () {
            this.loading.graph = true;
            let date = new Date(),
                days = new Date(Date.now() - (this.chart.days * 24 * 60 * 60 * 1000)),
                labels = this.progressData.filter(e =>
                    date.getTime() >= new Date(e.timestamp).getTime() && days.getTime() <= new Date(e.timestamp).getTime()
                ),
                array = labels.map(e => e.date_short);
            this.loading.graph = false;
            return array.reverse();
        },
        filteredData() {
            this.loading.graph = true;
            let date = new Date(),
                days = new Date(Date.now() - (this.chart.days * 24 * 60 * 60 * 1000)),
                data = this.progressData.filter(e =>
                    date.getTime() >= new Date(e.timestamp).getTime() && days.getTime() <= new Date(e.timestamp).getTime()
                ),
                array = data.map(e => e.value);
            this.loading.graph = false;
            return array.reverse();
        }
    },
    methods: {
        formatDate(value) {
            let date = new Date(value);
            return date.toLocaleDateString();
        },
        calcDifference(index) {
            let prev_index = index + 1;
            if(prev_index < this.progress.length) {
                let current = this.progress[index].input_value,
                    prev = this.progress[prev_index].input_value,
                    total = ((current - prev) / prev) * 100;
                return total.toFixed(1);
            } else {
                return 0;
            }
        },
        filterActive(days) {
            return days === this.chart.days;
        }
    }
}
</script>

<style lang="less" scoped>

@import '@site/css/variables.less';
@import '@core/css/mixins.less';
@import '@site/../default/css/base/mixins.less';

.progressTrackingWrap {
    background: @light_color;
    padding: 30px;
    border-radius: 15px;
    overflow: hidden;
    margin: 0 0 75px;

    .progressTracking {
        &.graph {
            display: flex;
            flex-flow: row nowrap;
            gap: 30px;

            .progressEntriesWrap {
                flex: 0 0 364px;

                .entriesBody {
                    flex-flow: column nowrap;
                    gap: 10px;
                }
            }

            .progressGraph {
                flex: 1 1;
            }
        }

        .progressEntriesWrap {
            .entriesHead {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;
                gap: 12px;
                margin: 0 0 25px;

                h4 {
                    .rems(18);
                    line-height: 25px;
                    margin: 0;
                }

                .button {
                    margin: 0;
                }
            }

            .entriesBody {
                display: flex;

                .progressEntry > a {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;

                    background: #ffffff;
                    color: @text_color;
                    border-radius: 10px;
                    overflow: hidden;

                    .progressContent {
                        flex: 1 1;
                        padding: 12px 24px;
                    }

                    .progressImage {
                        flex: 0 0 61px;
                        aspect-ratio: 1 / 1;
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        img {
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }

                    }

                    .svgIcon {
                        color: @mid_color;
                        height: 11px;
                        margin: 0 10px 0 0;
                    }

                    .progressWeight, .progressRating {
                        .rems(14);
                        line-height: 22px;
                    }

                    .progressDiff {
                        .rems(12);
                        line-height: 22px;
                        letter-spacing: 0.24px;
                        margin: 0 0 0 5px;
                        color: @primary_color;
                    }

                    .progressDate {
                        .rems(10);
                        line-height: 22px;
                        margin: 0 0 0 10px;
                    }
                }
            }
        }

        .progressGraph {
            .chartFilter {
                ul {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    gap: 8px;
                    margin: 0 0 20px;

                    &:before, &:after {
                        display: none;
                    }

                    li {
                        a {
                            display: block;
                            color: @text_color;
                            text-align: center;
                            position: relative;
                            padding: 0 0 17px;

                            &.active {
                                font-weight: 700;

                                &:after {
                                    content: '';
                                    height: 6px;
                                    width: 6px;
                                    border-radius: 50%;
                                    background: @mid_color;
                                    position: absolute;
                                    bottom: 5px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                            }
                        }
                    }
                }
            }

            .chartWrapper {
                background: #ffffff;
                border-radius: 13px;
                padding: 25px;
            }
        }

        &.list {
            .progressEntriesWrap {
                .entriesBody {
                    flex-flow: column wrap;
                    gap: 10px 35px;
                    max-height: 400px;

                    .progressEntry {

                        &:first-child {
                            width: 350px;

                            > a {
                                flex-flow: column-reverse nowrap;
                                align-items: unset;

                                .progressImage {
                                    flex: 1 1;
                                    width: 100%;
                                }
                            }
                        }

                        > a {
                            .progressImage {
                                flex: 0 0 92px;
                            }
                        }
                    }

                }
            }
        }
    }
}

</style>
