export const computed = {

    computed: {
        
        //Returns the question name for forms fields :name="questionName"
        questionName() {
            
            if(typeof this.multiuserId != 'undefined') {
                if (this.multiuserId) {
                    return 'questions[' + this.multiuserId + '][' + this.question.original_id + ']';
                }
            }
            if(typeof this.question != 'undefined') {
                if(typeof this.question.id != 'undefined') {
                    return 'questions[' + this.question.id + ']';
                }
            }
            
            return 'questions[noid]';
        }
    }
    
};

export const methods = {

    methods: {

        //Validate a question via event
        externallyValidate() {
            
            if(typeof this.question != 'undefined') {
                let question = this.question;
                question = this.validate(question);
                this.$emit('update-question', {
                    question: question,
                    externalValidation: true
                });
            }
            
        }
    }

};

export const props = {
    
    props: {
        question: {
            type: [Object, Array, Boolean],
            default (rawProps) {
                return [];
            }
        },
        multiuserId: {
            type: [String, Number, Boolean],
            default (rawProps) {
                return false;
            }
        }
    }
    
};
