<template>

    <span :class="'toolTip tooltip' + position.charAt(0).toUpperCase() + position.slice(1)">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16">
            <path 
                    d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
                    fill="currentColor"
            />
        </svg>

        <div class="tip">

            <h4 v-text="title" v-if="title.length"></h4>
            <p v-html="content" v-if="content.length"></p>

        </div>

    </span>

</template>

<script>


    export default {

        name: 'tooltip',

        props: {
            title: {
                type: String,
                default: ''
            },
            content: {
                type: String,
                default: ''
            },
            position: {
                type: String,
                default: 'bottom'
            },

        },


        methods: {},

        mounted() {}

    }

</script>
