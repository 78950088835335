<template>

    <div class="tagInput assessmentTags">

   
        <vue-tags-input
                v-model="tag"
                :tags="tags"
                @tags-changed="newTags => updateAnswer(newTags)"
                :autocomplete-items="autoCompleteValues"
                :placeholder="placeholder"
                @blur="updateQuestion(question)"
        />
        
        <input 
                type="hidden" 
                v-model="question.answer"
                :name="questionName"
                :id="'id_' + question.id"
                autocomplete="off"
        >
        
    </div> <!-- .tagitField -->
    
</template>

<script>

import _ from 'lodash';
import { props, computed, methods } from "../mixins/fields";

export default {
    name: "tagInput",
    mixins: [props, computed, methods],
    props: {
        placeholder: {
            type: [String],
            default (rawProps) {
                return 'Add tag';
            }
        },
        autocompleteItems: {
            type: [Array, Object],
            default (rawProps) {
                return [];
            }
        },
        defaultTags: {
            type: [String],
            default (rawProps) {
                return '';
            }
        }
    },
    computed: {
        autoCompleteValues() {
            
            let values = _.toArray(this.autocompleteItems);
            let output = [];
            
            values.forEach(val => {
                
                let obj = {
                    text: val
                }

                output.push(obj);
                
            });
            
            return output;

        }
    },
    data() {
        return {
            tag: '',
            tags: [],
            autoCompleteArray: []
        }
    },
    created() {
        this.tags = this.setTagsFromString();
    },
    methods: {
        
        setTagsFromString() {
            
            let tagString = this.question.answer;
            
            if(!tagString.length) {
                tagString = this.defaultTags;
            }
            
            let tags = [];
            
            if(tagString.length) {
                let splitTags = tagString.split(',');
                
                splitTags.forEach(tag => {

                    tags.push({
                        text: tag
                    });
                    
                });
            }
            
            return tags;
            
        },

        updateAnswer(newTags) {

            this.tags = newTags;
            
            let tagString = '';

            this.tags.forEach((tag, i) => {
                
                let str = '';
                
                if(i > 0) {
                    str += ',';
                }
                
                str += tag.text;
                
                tagString += str;

            });
        
            this.question.answer = tagString;

            this.updateQuestion(this.question);
            
        },
        
        updateQuestion: _.debounce(function(question) {

            question = this.validate(question);
            this.$emit('update-question', question);

        }, 200),

        validate(question) {

            let canProceed = true;

            if (!question.answer.length && question.is_required) {
                canProceed = false
            }

            question.can_proceed = canProceed;

            return question;

        }

        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .assessmentTags {
        
        .tagInput {


            .ti-input {

                .ti-tags {
                    border-radius: 59px;
                }

            }


        }

    }

</style>
