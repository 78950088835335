//Testing
import { config } from './config.js';
window.globalConfig = config;

window.$ = window.jQuery = require('jquery');
require("moment");

require("../../../core/js/plugins/mtc_jquery_ui.js");
require("../../../core/js/plugins/mtc_jquery_ui_touch_punch.js");

import Vue from 'vue';
window.Vue = Vue;
require('vue-resource');


// Amends to vue resource for our archaic server setup
Vue.http.options.emulateJSON = true;

import {EventBus} from "./event-bus";
window.EventBus = EventBus;

import {vueEventHub} from "./event-bus";
window.vueEventHub = vueEventHub;

//window.LazyLoad = require ("vanilla-lazyload");


import mtcMenu from './components/navigation/menu.vue';
Vue.component("mtc-menu", mtcMenu);

import trustpilotWidget from './components/shop/item/partials/trustpilot.vue';
Vue.component("trustpilot-widget",trustpilotWidget);

/*
* Font awesome
*/
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
library.add(faUpload, faCircleXmark, faXmark, faMagnifyingGlass, faTrash);
Vue.component('font-awesome-icon', FontAwesomeIcon);

//Slider
import { Splide, SplideSlide } from '@splidejs/vue-splide';
Vue.component('mtc-slider', Splide);
Vue.component('mtc-slide', SplideSlide);

/*
* Core
 */
require("../../../core/js/plugins/mtc_vue_directives.js");
require("../../../core/js/plugins/mtc_vue_filters.js");
require("../../../core/js/functions.js");
require("../../../core/js/plugins/mtc_cookies.js");
require("../../../core/js/plugins/mtc_chosen.js");
require("../../../core/js/plugins/mtc_easing.js");
require("../../../core/js/plugins/mtc_hover_intent.js");
require("../../../core/js/plugins/mtc_mobile_menu.js");
require("../../../core/js/plugins/mtc_multi_currency.js");
require("../../../core/js/plugins/mtc_responsive_tables.js");
require("../../../core/js/plugins/mtc_number_arrows.js");
require("../../../core/js/plugins/mtc_tabs.js");
require("../../../core/js/plugins/mtc_waypoints.js");
require("../../../core/js/plugins/mtc_slick.js");
require("../../../core/js/site_scripts/mtc_show_hide_menus.js");
require("../../../core/js/plugins/mtc_zoom.js");


require("../../../cms/js/mtc_contact.js");
require("../../../cms/js/mtc_faq_section.js");

require("../../../modules/Members/js/mtc_members.js");

require("../../../plugins/FeaturedBrands/js/mtc_featured_brands.js");
//require("../../../plugins/NewsletterSignup/js/mtc_Newsletter.js");
//require("../../../plugins/ComplimentedItems/js/mtc_complimented_items.js");
require("../../../plugins/GoogleMaps/js/mtc_google_maps.js");
//require("../../../plugins/CustomersAlsoViewed/js/mtc_customers_also_viewed.js");
require("../../../plugins/Slider/js/mtc_slider.js");
//require("../../../plugins/RecentlyViewed/js/mtc_recently_viewed.js");
//require("../../../plugins/TrustpilotAPI/js/mtc_trustpilot.js");
require("../../../plugins/Judopay/js/judopay.js");
require("../../../plugins/RyftPay/js/ryftpay.js");



require("../../../shop/js/mtc_checkout.js");
require("../../../shop/js/mtc_checkout_mobile.js");
require("../../../shop/js/mtc_cross_sell_tabs.js");
require("../../../shop/js/mtc_checkout_stripe.js");
require("../../../shop/js/mtc_checkout_worldpay.js");
require("../../../shop/js/mtc_payment_button.js");


import mtcMinibasket from './components/shop/basket/mtc_minibasket';
Vue.component('mtc-minibasket', mtcMinibasket);

import loadingSpinner from './components/loading_spinner';
Vue.component("loading-spinner", loadingSpinner);

import productSidebar from "./components/shop/item/product_sidebar.vue";
Vue.component('product-sidebar', productSidebar);

import BrowsePage from "./components/shop/browse/browse_page.vue";
Vue.component('browse-page', BrowsePage);

import multiCurrencyPrice from "./components/multi_currency_price.vue";
Vue.component('multi-currency-price', multiCurrencyPrice);

import stockBadge from './components/shop/item/partials/stock_badge';
Vue.component("stock-badge", stockBadge);

import productCard from "./components/shop/item/product_card.vue";
Vue.component("product-card", productCard);

import productImages from "./components/shop/item/partials/product_images";
Vue.component("product-images", productImages);

import productSlider from "./components/shop/featured/product_slider";
Vue.component("product-slider", productSlider);

import categoryProducts from "./components/shop/browse/category_products";
Vue.component("category-products", categoryProducts);

import imagesBeforeAfter from "./components/shop/category/images_before_after";
Vue.component("images-before-after", imagesBeforeAfter);

import assessmentSteps from "./components/assessment/external/assessment_steps";
Vue.component("assessment-steps", assessmentSteps);

import assessmentForm from "./components/assessment/assessment_form";
Vue.component("assessment-form", assessmentForm);

import gpSurgerySearch from './components/gp_surgery_search';
Vue.component("gp-surgery-search", gpSurgerySearch);

import Tooltip from './components/tooltip';
Vue.component("tooltip", Tooltip);

import TreatmentTracker from "./components/members/dashboard/treatment_tracker";
Vue.component("treatment-tracker", TreatmentTracker);

import ProgressTabs from "./components/members/dashboard/progress_tabs";
Vue.component("progress-tabs", ProgressTabs);


require ("./site_scripts/mtc_menu.js");
require ("./site_scripts/mtc_accordions.js");
require ("./site_scripts/mtc_range_slider.js");
require ("./site_scripts/mtc_content_sidebar.js");
require ("./site_scripts/mtc_item.js");
require ("./site_scripts/mtc_featured_products.js");
require ("./site_scripts/mtc_faqs.js");
require ("./site_scripts/mtc_countdown.js");
require ("./site_scripts/mtc_mobile_search_bar.js");
require ("./site_scripts/mtc_nhs_map.js");
require ("./site_scripts/mtc_our_other_services.js");
require ("./site_scripts/mtc_timers.js");
require ("./site_scripts/mtc_testimonial_slider.js");
require ("./site_scripts/mtc_stats_banner.js");
require ("./site_scripts/mtc_sticky.js");
require ("./site_scripts/mtc_service_list.js");
require ("./site_scripts/mtc_site_wide_messages.js");
require ("./site_scripts/mtc_selling_points.js");
require ("./site_scripts/mtc_search.js");
require ("./site_scripts/mtc_search_v2.js");
require ("./site_scripts/mtc_popular_clinics.js");
require ("./site_scripts/mtc_title_banner.js");
require ("./site_scripts/mtc_information_banner.js");
require ("./site_scripts/mtc_slider.js");
require ("./site_scripts/mtc_cta_tabs.js");
require ("./site_scripts/mtc_video_tabs.js");
require ("./site_scripts/mtc_category_tabs.js");
require ("./site_scripts/mtc_sidescroller.js");
require("./site_scripts/mtc_overlay.js");


// Members verification
require ("./site_scripts/mtc_account_verify.js");


//Sidebar Functionality
require ("./site_scripts/mtc_sidebar.js");


//NHS Prescriptions
require ("./site_scripts/mtc_nhs_prescriptions.js");

import DatePicker from './components/date_picker.vue';
Vue.component('datepicker', DatePicker);

import Chosen from './components/chosen.vue';
Vue.component('chosen', Chosen);


import ThemeToggle from './components/mtc_theme_toggle.vue';
Vue.component('theme-toggle', ThemeToggle);

/*
* Replacement for tagit
 */
import VueTagsInput from '@johmun/vue-tags-input';
Vue.component('vue-tags-input', VueTagsInput);

import MtcTagInput from './components/mtc_tag_input.vue';
Vue.component('mtc-tag-input', MtcTagInput);

//Assessment Store
import store from './components/store/store.js';


$.holdReady(true);

window.vue_environment = new Vue({
    el: '#vue_environment',
    store,
    delimiters: ['[%', '%]'],
    mounted() {
        $.holdReady(false);
    }
});

require ("./script.js");
