<template>

    <div 
            class="questionWrap"
            :class="validationClass"
            v-if="question.visible"
            :id="'question_wrap_' + question.id"
    >
    
        <div class="row">

            <div class="questionNumberCol" v-if="questionNumber">
                <div class="questionNumber">
                    <span v-html="questionNumber"></span>
                </div>
            </div> <!-- .questionNumberCol -->

            <label 
                    :for="'question_' + question.id" 
                    v-html="question.question_text"
                    class="questionText"
            ></label>

            <div class="formError" v-if="hasError">
                <p v-html="errorMessage"></p>
            </div> <!-- .tester -->
            
            <div class="inputWrap">
           
                <yes-no 
                        v-if="matchType('yes_no')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="yesNo"
                        :multiuser-id="multiuserId"
                />

                <variations
                        v-else-if="matchType('variations')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="variations"
                        :multiuser-id="multiuserId"
                />

                <confirmation
                        v-else-if="matchType('confirmation')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="confirmation"
                        :multiuser-id="multiuserId"
                />

                <date-field
                        v-else-if="matchType('date')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="dateField"
                        :multiuser-id="multiuserId"
                />

                <number-field
                        v-else-if="matchType('number')"
                        :question="question"
                        :allow-input-event="changed"
                        @update-question="updateQuestion"
                        ref="number"
                        :multiuser-id="multiuserId"
                />

                <email-field
                        v-else-if="matchType('email')"
                        :question="question"
                        placeholder="example@website.com"
                        :allow-input-event="changed"
                        @update-question="updateQuestion"
                        ref="email"
                        :multiuser-id="multiuserId"
                />

                <image-upload
                        v-else-if="matchType('image')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="image"
                        :multiuser-id="multiuserId"
                />

                <tag-input
                        v-else-if="matchType('allergies')"
                        :question="question"
                        :default-tags="$store.state.user.healthProfile.allergies"
                        placeholder="Add allergy"
                        :autocomplete-items="[]"
                        @update-question="updateQuestion"
                        ref="tagInput"
                        :multiuser-id="multiuserId"
                />

                <tag-input
                        v-else-if="matchType('medications')"
                        :question="question"
                        :default-tags="$store.state.user.healthProfile.medications"
                        placeholder="Add medication"
                        :autocomplete-items="$store.state.medicationsList"
                        @update-question="updateQuestion"
                        ref="tagInput"
                        :multiuser-id="multiuserId"
                />

                <tag-input
                        v-else-if="matchType('conditions')"
                        :question="question"
                        :default-tags="$store.state.user.healthProfile.conditions"
                        placeholder="Add condition"
                        :autocomplete-items="$store.state.medicalConditionList"
                        @update-question="updateQuestion"
                        ref="tagInput"
                        :multiuser-id="multiuserId"
                />

                <textarea-field
                        v-else-if="matchType('textarea')"
                        :question="question"
                        placeholder=""
                        :allow-input-event="changed"
                        @update-question="updateQuestion"
                        ref="text"
                        :multiuser-id="multiuserId"
                />

                <notify-gp
                        v-else-if="matchType('notify_gp')"
                        :question="question"
                        @update-question="updateQuestion"
                        ref="notifyGP"
                        :multiuser-id="multiuserId"
                />
                
                <bmi-calculator
                        v-else-if="matchType('bmi')"
                        :question="question"
                        :set-type="question.bmi_field_values.type"
                        :set-height-cm="question.bmi_field_values.height_cm"
                        :set-weight-kg="question.bmi_field_values.weight_kg"
                        :set-height-feet="question.bmi_field_values.height_feet"
                        :set-height-inches="question.bmi_field_values.height_inches"
                        :set-weight-stone="question.bmi_field_values.weight_stone"
                        :set-weight-pounds="question.bmi_field_values.weight_pounds"
                        @update-question="updateQuestion"
                        ref="bmi"
                        :multiuser-id="multiuserId"
                />

                <weight-field
                        v-else-if="matchType('weight')"
                        :question="question"
                        :set-type="question.weight_field_values.type"
                        :set-weight-kg="question.weight_field_values.weight_kg"
                        :set-weight-stone="question.weight_field_values.weight_stone"
                        :set-weight-pounds="question.weight_field_values.weight_pounds"
                        @update-question="updateQuestion"
                        ref="weight"
                        :multiuser-id="multiuserId"
                />

                <travel-users
                        v-else-if="matchType('travel_users')"
                        :question="question"
                        placeholder-name="John Smith"
                        placeholder-dob="dd/mm/yyyy"
                        ref="travel_users"
                        @update-question="updateQuestion"
                />

                <travel-dates
                        v-else-if="matchType('travel_dates')"
                        :question="question"
                        ref="travel_dates"
                        @update-question="updateQuestion"
                />

                <travel-destinations
                        v-else-if="matchType('travel_destinations')"
                        :question="question"
                        ref="travel_destinations"
                        @update-question="updateQuestion"
                />


                <template v-if="!matchType('travel_destinations') && !matchType('travel_dates') && !matchType('travel_users')">
    
                    <template v-if="multiuserId">
                        
                        <input type="hidden"
                               :name="'change_count[' + multiuserId + '][' + question.original_id + ']'"
                               v-model="question.answer_change_count"
                        />
                    </template>
                    
                    <template v-else>
                        <input type="hidden"
                               :name="'change_count[' + question.id + ']'"
                               v-model="question.answer_change_count"
                        />
                    </template>

                </template>
                
                
            </div> <!-- .inputWrap -->
            
        </div> <!-- .row -->
        
    </div> <!-- .questionWrap -->
    
</template>

<script>


import _ from 'lodash';
import yesNo from '../fields/yes_no';
import variations from '../fields/variations';
import confirmation from '../fields/confirmation';
import dateField from '../fields/date_field';
import numberField from '../fields/number_field';
import weightField from '../fields/weight_field';
import emailField from '../fields/email_field';
import imageUpload from '../fields/image_upload';
import tagInput from '../fields/tag_input';
import textareaField from '../fields/textarea_field';
import notifyGp from '../fields/notify_gp';
import bmiCalculator from '../fields/bmi_calculator';

import travelUsers from '../fields/travel_users';
import travelDates from '../fields/travel_dates';
import travelDestinations from '../fields/travel_destinations';

export default {
    name: 'questionWrap',
    components: {
        yesNo,
        variations,
        confirmation,
        dateField,
        numberField,
        weightField,
        emailField,
        imageUpload,
        tagInput,
        textareaField,
        notifyGp,
        bmiCalculator,
        travelUsers,
        travelDates,
        travelDestinations
    },
    props: {
        questionNumber: {
            type: [Number, String],
            default (rawProps) {
                return 0;
            }
        },
        questionId: {
            type: [Number, String],
            default (rawProps) {
                return 0;
            }
        },
        travelQuestion: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserQuestion: {
            type: [Boolean],
            default (rawProps) {
                return false;
            }
        },
        multiuserId: {
            type: [Number, String, Boolean],
            default (rawProps) {
                return false;
            }
        },
    },
    data() {
        return {
            changed: false,
            defaultErrorMessages: {
                email: 'To continue, please enter a valid email address',
                yes_no: 'To continue, please select an option',
                textarea: 'To continue, please fill out this text box',
                number: 'To continue, please enter a number',
                weight: 'To continue, please enter a number',
                conditions: 'To continue, please enter your conditions into the box provided',
                medications: 'To continue, please enter your current medications into the box provided',
                allergies: 'To continue, please enter any allergies you have in the box provided',
                notify_gp: 'To continue, please search and add your GP\s address or enter it manually',
                image: 'To continue, please upload an image',
                variations: 'To continue, please select an option',
                confirmation: 'To continue, please select an option from below',
                bmi: 'To continue, please enter your height and weight',
                date: 'To continue, please select a valid date',
                travel_users: 'To continue, please add travellers using their full name(s) and date(s) of birth in dd/mm/yyyy format',
                travel_dates: 'To continue, please enter your travel departure and return dates',
                travel_destinations: 'To continue, please enter your travel destinations'
            }
        }
    },
    mounted() {
        //Set visibility when preloading sections/questions
        this.updateVisibility(this.question);
        this.updateQuestion(this.question);
    },
    computed: {
        
        hasError() {
            return this.question.has_error || (this.question.question_type == 'image' && this.question?.file_error?.length);
        },
        
        errorMessage() {
            
            let error = this.defaultErrorMessages[this.question.question_type];
            
            if(this.question.question_type == 'image') {
                
                if(this.question?.file_error?.length) {
                    error = this.question.file_error;
                }
                
            }else if(!_.isUndefined(this.question.error_message)) {

                if(this.question.error_message.length) {
                    error = this.question.error_message;
                }
            }
            
            return error;
            
        },
        
        question() {
            
            return this.getQuestion();
           
        },
        
        validationClass() {
            
            let question = this.getQuestion();
           
            let cssClass = [];
            
            if(question.has_error) {
                cssClass.push('hasError');
            }else if(question.can_proceed && this.changed) {
                cssClass.push('isValid');
            }
            
            return cssClass;
        }
        
    },
    methods: {
        
        getQuestion() {
            
            if(this.multiuserQuestion) {

                return this.getQuestionByKey('id', this.questionId, false, 'questions', this.multiuserId);

            }else if(this.travelQuestion) {

                return this.getQuestionByKey('id', this.questionId, false, 'travel_questions');

            }else{

                return this.getQuestionByKey('id', this.questionId);

            }
            
        },
        
        matchType(type) {

            let question = this.getQuestion();
            
            if(!_.isUndefined(question.question_type)) {
                return question.question_type == type;
            }else{
                return false;
            }
        },
        
        updateQuestion(data) {

            let externalValidation = false;
            let question = data;
            
            if(typeof question.externalValidation != 'undefined') {
                question = data.question;
                externalValidation = data.externalValidation;
            }

            //Save answers
            if(this.changed) {
                this.$store.dispatch('storeAnswers', true);
            }
            
            this.changed = true;
            
            question = this.setErrors(question);
            
            question.answer_change_count++;

            if(this.multiuserQuestion) {
                
                this.$store.dispatch('updateAssessmentMultiuserQuestion', question, this.multiuserId);
                
            }else if(this.travelQuestion) {
                
                this.$store.dispatch('updateAssessmentTravelQuestion', question);
                
            }else{
                
                this.$store.dispatch('updateAssessmentQuestion', question);
                
            }

            //Track progress
            this.$store.dispatch('updateProgress', true);
            
            
            this.updateVisibility(question);

            if(this.multiuserQuestion) {
                this.$emit('update-errors', {
                    multiuserId: this.multiuserId
                });
            }

            //Force next step
           
            if(typeof question.direct_proceed != 'undefined' && !externalValidation) {
                
                if (question.direct_proceed) {
                 
                    this.$emit('direct-proceed', question);
                    
                }
                
            }

            this.$store.dispatch('updateQuestionNumbers', {});
            
            
        },
        
        setErrors(question) {
            
            if(question.can_proceed) {
                question.has_error = false;
            }else{
                question.has_error = true;
            }
            
            return question;
        },
        
        updateVisibility(question) {
            
            
            let externalQs;

            if(this.multiuserQuestion) {
                externalQs = this.getQuestionByKey('show_if_question', question.id, true, 'questions', this.multiuserId);
            }else{
                externalQs = this.getQuestionByKey('show_if_question', question.id, true);
            }

            if(externalQs.length) {

                externalQs.forEach(externalQ => {

                    if((externalQ.show_if_answer == question.answer) || (question.is_multiple && question.answers.indexOf(externalQ.show_if_answer) > -1) ) {
                        externalQ.visible = true;
                    }else{
                        externalQ.visible = false;
                    }

                    if(this.multiuserQuestion) {
                        this.$store.dispatch('updateAssessmentMultiuserQuestion', question, this.multiuserId);
                    }else {
                        this.$store.dispatch('updateAssessmentQuestion', externalQ);
                    }
                    
                });
                
                
            }
           
            
        },
        
        getQuestionByKey(key, value, multiple, type, multiUserID) {


            const _this = this;
            
            if(_.isUndefined(multiple)) {
                multiple = false;
            }

            if(_.isUndefined(type)) {
                type = 'questions';
            }

            if(_.isUndefined(multiUserID)) {
                multiUserID = false;
            }
            
            
            let question = false,
                questions = [];
            
            
            if(multiUserID) {
               
               
                for (const userIndex in _this.$store.state.assessment.multiusers) {
                    
                    const user = _this.$store.state.assessment.multiusers[userIndex];

                    if(user.id == multiUserID) {
                      
                        for (const questionIndex in user[type]) {

                            let q = _this.$store.state.assessment.multiusers[userIndex][type][questionIndex];

                            if (q.hasOwnProperty(key)) {

                                if (q[key] == value) {
                                    question = q;
                                    questions.push(q);
                                }

                            }

                        }

                    }

                }
            

            }else {

                for (let sIndex in _this.$store.state.assessment.sections) {

                    for (let qIndex in _this.$store.state.assessment.sections[sIndex][type]) {

                        let q = _this.$store.state.assessment.sections[sIndex][type][qIndex];

                        if (q.hasOwnProperty(key)) {

                            if (q[key] == value) {
                                question = q;
                                questions.push(q);
                            }

                        }

                    }

                }

            }
            
            return multiple ? questions : question;
            
        },
        
        externalValidation() {

            const _this = this;

            if( !_.isEmpty(this.$refs) && !_.isUndefined(this.$refs)) {
                
                for(const prop in this.$refs) {
                    
                    if(!_.isUndefined(_this.$refs[prop])) {
                        _this.$refs[prop].externallyValidate();
                    }
                    
                }
                

            }
            
        }
        
    }
}
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .questionWrap {
        
        background: #fff;
        padding: 27px 0;
        border-bottom: 1px solid @border_color;
        border-radius: 0;
        margin: 0;
        color: @dark_primary;
        
        &:first-child {
            border-top: none;
        }
        
        &.hasError {

            .questionText {
                
                & > p {

                    &:first-of-type {
                        color: @alert_color;
                        transition: color 200ms linear;
                    }
                }
                
            }
        }

        &.isValid {
            transition: border-color 200ms linear;
        }
        
        & > .row {
            margin: 0;
            position: relative;
            float: none;
            clear: both;
            display: flex;
            align-items: center;
            gap: 16px;
            
            @media @flyout {
                flex-wrap: wrap;
                gap: 0;
            }
            
            &:before, &:after {
                display: none;
            }
            
            .questionNumberCol {
                flex: 0 0 70px;

                @media @flyout {
                    flex: 0 0 48px;
                }
            }
            
            .questionText {
                flex: 1 1 100%;

                @media @flyout {
                    flex: 0 0;
                    flex-basis: ~"calc(100% - 48px)";
                }
            }
            
            & > .inputWrap {
                flex: 0 1 100%;
                display: flex;
                justify-content: flex-end;
                min-width: 300px;

                @media @flyout {
                    flex: 0 0 100%;
                    min-width: 100%;
                    padding: 24px 0 0 48px;
                    justify-content: flex-start;
                }
                
                @media @mobile {
                    padding: 24px 0 0 0;
                }
                
                .optionButtons {
                    @media @mobile {
                        width: 100%;
                    }
                }
                
                .notifyGP {
                    
                    @media @flyout {
                        flex: 0 0 100%;
                        min-width: 100%;
                    }
                    
                }
                
            }
        }
        
        .questionNumberCol {
            
            .questionNumber {
                display: flex;
                justify-content: center;
                align-items: center;
                .rems(17);
                font-weight: 500;
                line-height: 29px;
                color: @dark_primary;
                border-radius: 45px;
                border: 1px solid rgba(0, 0, 0, 0.30);
                padding: 8.5px 8px 7.5px 8px;
                width: 30px;
                height: 30px;
                
            }
            
        }
        
        .inputWrap {
            margin: 0;
            padding: 0;
            position: relative;
            float: none;
            clear: both;
            
        }
        
        .questionText {
            .rems(17);
            line-height: 22px;
            font-weight: 600;
            color: inherit;
            margin: 0;
            position: relative;
            float: none;
            clear: both;
            min-width: 400px;
            
            @media @flyout {
                min-width: 0;
            }
            
            & > p {
                &:first-of-type {
                    font-weight: 700;
                    transition: color 200ms linear;
                }
            }
            
            p {
                &:last-of-type {
                    margin: 0;
                }
            }
            
            ul, ol {
                
                text-transform: none;
                margin: 0;
                padding: 24px 0 0 30px;
                text-align: left !important;
                
                &:before, &:after {
                    display: none;
                }
                
                li {
                    .rems(14);
                    line-height: 1.2em;
                    font-weight: 500;
                    padding: 0 0 15px 5px;
                }
            }
            
            ul {
                
                padding: 24px 0 0 14px;
                
                li {
                    list-style: none;
                    position: relative;
                    .rems(14);
                    line-height: 1.2em;
                    font-weight: 500;
                    padding: 0 0 15px 22px;

                    @svg_color: escape(@primary_color);

                    &:before {
                        content: url("data:image/svg+xml; utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' %3E%3Cpath d='M11.3671 3.75949H8.24051V0.632911C8.24051 0.278481 7.94939 0 7.60759 0H4.39241C4.03797 0 3.75949 0.291117 3.75949 0.632911V3.75949H0.632911C0.278481 3.75949 0 4.05061 0 4.39241V7.60759C0 7.96203 0.291117 8.24051 0.632911 8.24051H3.75949V11.3671C3.75949 11.7215 4.05061 12 4.39241 12H7.60759C7.96203 12 8.24051 11.7089 8.24051 11.3671V8.26582H11.3671C11.7215 8.26582 12 7.97471 12 7.63291V4.41772C12 4.05059 11.7089 3.75947 11.3671 3.75947V3.75949Z' fill='@{svg_color}'/%3E%3C/svg%3E");
                        color: @primary_color;
                        position: absolute;
                        width: 12px;
                        height: 12px;
                        left: 0;
                        top: 0;
                        margin-top: 1.5px;
                        display: inline-block;
                    }
                }
            }
        }
        
        .formError {
            color: @alert_color;
            padding: 4px 0 8px 0;
            text-transform: none;
            display: none !important;
            
            p {
                margin: 0;
            }
        }
        
    }
    
    

</style>
