var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "productSidebar" },
    [
      _c("div", { staticClass: "itemTitle" }, [
        _vm.product.name
          ? _c("h1", {
              staticClass: "mainTitle",
              domProps: { innerHTML: _vm._s(_vm.product.name) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.product.custom.product_subtitle
          ? _c("h3", {
              staticClass: "itemSubtitle",
              domProps: {
                innerHTML: _vm._s(_vm.product.custom.product_subtitle),
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.productBadge.text.length && _vm.showBadge
          ? _c("div", {
              staticClass: "productBadge",
              class: [_vm.productBadge.class],
              domProps: { innerHTML: _vm._s(_vm.productBadge.text) },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.showStockBadge
        ? [
            _c("stock-badge", {
              attrs: { "has-stock": _vm.hasStock, small: false },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showPrice && _vm.price && _vm.fromTextEnabled
        ? _c(
            "div",
            { staticClass: "fromPrice" },
            [
              _c("span", { staticClass: "fromText" }, [_vm._v("From")]),
              _vm._v(" "),
              _c("multi-currency-price", { attrs: { price: _vm.price } }),
              _vm._v(" "),
              _vm.pricePerTablet > 0
                ? _c("multi-currency-price", {
                    attrs: {
                      price: _vm.pricePerTablet,
                      postfix: " / " + _vm.pillTYpe,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.inStock
        ? _c("out-of-stock-notify", {
            attrs: {
              "url-submit": _vm.outOfStockUrl,
              "button-label": "Notify me when back in stock",
              "shop-item-id": _vm.product.id,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.product.description
        ? _c("div", {
            staticClass: "itemDescription wysiwyg",
            domProps: { innerHTML: _vm._s(_vm.product.description) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("add-to-basket", {
        attrs: {
          "ajax-add-to-basket":
            _vm.$store.state.minibasket.productCardsQuickAdd,
          "basket-button-data": _vm.basketButtonData,
          product: _vm.product,
          "default-size-selected-id": _vm.defaultSelectedSizeID,
          "show-price": true,
          "price-set": _vm.price,
          "large-button": true,
          "single-size-select": false,
        },
        on: { sizeupdated: _vm.updateSize, sizechanged: _vm.sizeChanged },
      }),
      _vm._v(" "),
      _c("delivery-countdown", { attrs: { cutoff: _vm.deliveryCutoff } }),
      _vm._v(" "),
      _vm.product.warnings?.length
        ? _c(
            "div",
            { staticClass: "doctorWarnings" },
            [
              _vm._l(_vm.product.warnings, function (warning) {
                return [
                  _c("div", {
                    class: warning.type,
                    domProps: { innerHTML: _vm._s(warning.content) },
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }