var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tagInput" },
    [
      _c("vue-tags-input", {
        attrs: {
          tags: _vm.tags,
          placeholder: _vm.inputPlaceholder,
          "autocomplete-items": _vm.autocompleteObjects,
        },
        on: { "tags-changed": (newTags) => _vm.updateTags(newTags) },
        model: {
          value: _vm.tag,
          callback: function ($$v) {
            _vm.tag = $$v
          },
          expression: "tag",
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.tagString,
            expression: "tagString",
          },
        ],
        attrs: { type: "hidden", name: _vm.inputName },
        domProps: { value: _vm.tagString },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.tagString = $event.target.value
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }