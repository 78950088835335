var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "beforeAfter", class: _vm.cssClass }, [
    _c("div", { staticClass: "wrapper" }, [
      _vm.sectionTitle.length
        ? _c("h2", {
            staticClass: "beforeAfter__title",
            domProps: { innerHTML: _vm._s(_vm.sectionTitle) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("nav", { staticClass: "beforeAfter__nav" }, [
        _c(
          "ul",
          { staticClass: "beforeAfter__navList" },
          _vm._l(_vm.blocks, function (block, index) {
            return _c("li", { key: "imageNav" + index }, [
              _c(
                "a",
                {
                  class: [block.visible ? "active" : ""],
                  attrs: {
                    href: "beforeAfter_" + (index + 1),
                    title: "View before and after Example #" + (index + 1),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setVisible(index)
                    },
                  },
                },
                [
                  _vm._v("\n                        Example #"),
                  _c("span", { domProps: { innerHTML: _vm._s(index + 1) } }),
                ]
              ),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "beforeAfter__sliders" },
        _vm._l(_vm.blocks, function (block, index) {
          return block.visible
            ? _c(
                "div",
                {
                  key: "imageSlide" + index,
                  staticClass: "beforeAfterWrap",
                  attrs: { id: "beforeAfter_" + (index + 1) },
                },
                [
                  _c("div", { staticClass: "beforeAfterWrap__beforeContent" }, [
                    _c(
                      "div",
                      { staticClass: "beforeAfterWrap__innerContent" },
                      [
                        block["Before Title"]["value"].length
                          ? _c("h3", {
                              staticClass: "beforeAfterWrap__title",
                              domProps: {
                                innerHTML: _vm._s(
                                  block["Before Title"]["value"]
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        block["Before Content"]["value"]
                          ? _c("div", {
                              staticClass: "beforeAfterWrap__content wysiwyg",
                              domProps: {
                                innerHTML: _vm._s(
                                  block["Before Content"]["value"]
                                ),
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "beforeAfterImages" }, [
                    _c(
                      "div",
                      {
                        staticClass: "beforeAfterImages__container",
                        style: _vm.blockPositionCSS(_vm.blocks[index].position),
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "beforeAfterImages__images" },
                          [
                            _c("img", {
                              staticClass: "imageBefore sliderImage",
                              attrs: {
                                src:
                                  _vm.imageFolder +
                                  block["Before Image"]["value"],
                                alt: block["Before Image"]["Alt"],
                                width: "882",
                                height: "666",
                              },
                            }),
                            _vm._v(" "),
                            _c("img", {
                              staticClass: "imageAfter sliderImage",
                              attrs: {
                                src:
                                  _vm.imageFolder +
                                  block["After Image"]["value"],
                                alt: block["After Image"]["Alt"],
                                width: "882",
                                height: "666",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.blocks[index].position,
                              expression: "blocks[index].position",
                            },
                          ],
                          staticClass: "beforeAfterImages__slider",
                          attrs: {
                            type: "range",
                            min: "0",
                            max: "100",
                            "aria-label": "Percentage of before image to shown",
                          },
                          domProps: { value: _vm.blocks[index].position },
                          on: {
                            __r: function ($event) {
                              return _vm.$set(
                                _vm.blocks[index],
                                "position",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "beforeAfterImages__line",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "beforeAfterImages__button",
                            attrs: { "aria-hidden": "true" },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "27",
                                  height: "16",
                                  viewBox: "0 0 27 16",
                                  fill: "none",
                                },
                              },
                              [
                                _c("g", [
                                  _c("path", {
                                    attrs: {
                                      d: "M25.9789 9.12557L19.5753 15.5292C19.125 15.9794 18.4246 16.1295 17.8243 15.8794C17.224 15.6292 16.8237 15.0289 16.8237 14.3785V1.57132C16.8237 0.920952 17.224 0.370642 17.8243 0.120501C18.4246 -0.129639 19.125 0.020445 19.5753 0.470698L25.9789 6.8743C26.6292 7.47464 26.6292 8.52523 25.9789 9.12557Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      d: "M1.45569 9.12557L7.85929 15.5292C8.30955 15.9794 9.00994 16.1295 9.61028 15.8794C10.2106 15.6292 10.6108 15.0289 10.6108 14.3785V1.57132C10.6108 0.920952 10.2106 0.370642 9.61028 0.120501C9.00994 -0.129639 8.30955 0.020445 7.85929 0.470698L1.45569 6.8743C0.805323 7.47464 0.805323 8.52523 1.45569 9.12557Z",
                                      fill: "currentColor",
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "beforeAfterWrap__afterContent" }, [
                    _c(
                      "div",
                      { staticClass: "beforeAfterWrap__innerContent" },
                      [
                        block["After Title"]["value"].length
                          ? _c("h3", {
                              staticClass: "beforeAfterWrap__title",
                              domProps: {
                                innerHTML: _vm._s(
                                  block["After Title"]["value"]
                                ),
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        block["After Content"]["value"].length
                          ? _c("div", {
                              staticClass: "beforeAfterWrap__content wysiwyg",
                              domProps: {
                                innerHTML: _vm._s(
                                  block["After Content"]["value"]
                                ),
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              )
            : _vm._e()
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }