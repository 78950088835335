var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "progressTrackingWrap" }, [
    _c(
      "div",
      { staticClass: "progressTracking", class: [_vm.trackerType] },
      [
        _vm.trackerType === "graph"
          ? [
              _c("div", { staticClass: "progressEntriesWrap" }, [
                _c("div", { staticClass: "entriesHead" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.category.name))]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button buttonOutlined",
                      attrs: {
                        href:
                          "/members/progress_tracker/?category_id=" +
                          _vm.category.id,
                      },
                    },
                    [
                      _vm._v(
                        "\n                        View all entries\n                    "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "entriesBody" },
                  _vm._l(_vm.progressData.slice(0, 4), function (entry, index) {
                    return _c(
                      "div",
                      {
                        key: "entry-" + entry.id,
                        staticClass: "progressEntry",
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/members/progress_tracker/edit.php?category_id=" +
                                entry.cat +
                                "&id=" +
                                entry.id,
                            },
                          },
                          [
                            _c("div", { staticClass: "progressContent" }, [
                              Math.sign(entry.diff) === 1
                                ? _c("i", { staticClass: "svgIcon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 10 11",
                                          fill: "none",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M4.99376 5.16015e-07L10 4.90111L10 6.74667L5.66792 2.50556L5.66791 11L4.33208 11L4.33209 2.49333L-5.80768e-07 6.73444L-4.19424e-07 4.88889L4.99376 5.16015e-07Z",
                                            fill: "currentColor",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : Math.sign(entry.diff) === 0
                                ? _c("i", { staticClass: "svgIcon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 10 2",
                                          fill: "none",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z",
                                            fill: "currentColor",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : Math.sign(entry.diff) === -1
                                ? _c("i", { staticClass: "svgIcon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 10 11",
                                          fill: "none",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M5.00624 11L0 6.09889L0 4.25333L4.33209 8.49444L4.33209 0L5.66792 0L5.66792 8.50667L10 4.26556V6.11111L5.00624 11Z",
                                            fill: "currentColor",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", { staticClass: "progressWeight" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(entry.value) +
                                    "kg\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "progressDiff" }, [
                                _vm._v(
                                  "\n                                    (" +
                                    _vm._s(entry.diff) +
                                    "%)\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "progressDate" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(entry.date) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "progressImage" }, [
                              typeof entry.image !== "undefined" &&
                              entry.image !== null
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        "/uploads/images/progress_items/" +
                                        entry.image,
                                      alt:
                                        _vm.memberName +
                                        "'s " +
                                        _vm.category.name +
                                        " Update " +
                                        entry.date,
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "/sites/default/images/no-image.jpg",
                                      alt:
                                        _vm.memberName +
                                        "'s " +
                                        _vm.category.name +
                                        " Update " +
                                        entry.date,
                                    },
                                  }),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "progressGraph" }, [
                _c("div", { staticClass: "chartFilter" }, [
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.chart.filter, function (day) {
                        return _c("li", [
                          _c("a", {
                            class: { active: _vm.filterActive(day) },
                            attrs: { href: "#" + day },
                            domProps: {
                              innerHTML: _vm._s("Last " + day + " days"),
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.chart.days = day
                              },
                            },
                          }),
                        ])
                      }),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            class: { active: _vm.filterActive(365) },
                            attrs: { href: "#365" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                _vm.chart.days = 365
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                Last year\n                            "
                            ),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "chartWrapper" },
                  [
                    _c("LineChart", {
                      style: _vm.myStyles,
                      attrs: {
                        data: _vm.chartData,
                        options: _vm.chart.options,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          : _vm.trackerType === "list"
          ? [
              _c("div", { staticClass: "progressEntriesWrap" }, [
                _c("div", { staticClass: "entriesHead" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.category.name))]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button buttonOutlined",
                      attrs: {
                        href:
                          "/members/progress_tracker/?category_id=" +
                          _vm.category.id,
                      },
                    },
                    [
                      _vm._v(
                        "\n                        View all entries\n                        "
                      ),
                      _c("i", { staticClass: "svgIcon" }, [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 10 9",
                              fill: "none",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M10 4.49438L5.54444 9L3.86667 9L7.72222 5.10112L4.64936e-08 5.10112L6.08303e-08 3.89888L7.73333 3.89888L3.87778 4.6242e-08L5.55556 6.62493e-08L10 4.49438Z",
                                fill: "currentColor",
                              },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "entriesBody" },
                  _vm._l(_vm.progressData.slice(0, 5), function (entry, index) {
                    return _c(
                      "div",
                      {
                        key: "entry-" + entry.id,
                        staticClass: "progressEntry",
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/members/progress_tracker/edit.php?category_id=" +
                                entry.cat +
                                "&id=" +
                                entry.id,
                            },
                          },
                          [
                            _c("div", { staticClass: "progressContent" }, [
                              entry.rating === "0"
                                ? _c("i", { staticClass: "svgIcon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 512 512",
                                          fill: "currentColor",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : entry.rating === "2"
                                ? _c("i", { staticClass: "svgIcon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 10 2",
                                          fill: "none",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M0 1C0 0.447715 0.447715 0 1 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H1C0.447715 2 0 1.55228 0 1Z",
                                            fill: "currentColor",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : entry.rating === "1"
                                ? _c("i", { staticClass: "svgIcon" }, [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 512 512",
                                          fill: "currentColor",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d: "M0 56v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56zm40 200c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24zm272 256c-20.183 0-29.485-39.293-33.931-57.795-5.206-21.666-10.589-44.07-25.393-58.902-32.469-32.524-49.503-73.967-89.117-113.111a11.98 11.98 0 0 1-3.558-8.521V59.901c0-6.541 5.243-11.878 11.783-11.998 15.831-.29 36.694-9.079 52.651-16.178C256.189 17.598 295.709.017 343.995 0h2.844c42.777 0 93.363.413 113.774 29.737 8.392 12.057 10.446 27.034 6.148 44.632 16.312 17.053 25.063 48.863 16.382 74.757 17.544 23.432 19.143 56.132 9.308 79.469l.11.11c11.893 11.949 19.523 31.259 19.439 49.197-.156 30.352-26.157 58.098-59.553 58.098H350.723C358.03 364.34 384 388.132 384 430.548 384 504 336 512 312 512z",
                                          },
                                        }),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("span", { staticClass: "progressRating" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.rating[entry.rating]) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "progressDate" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(entry.date) +
                                    "\n                                "
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "progressImage" }, [
                              typeof entry.image !== "undefined" &&
                              entry.image !== null
                                ? _c("img", {
                                    attrs: {
                                      src:
                                        "/uploads/images/progress_items/" +
                                        entry.image,
                                      alt:
                                        _vm.memberName +
                                        "'s " +
                                        _vm.category.name +
                                        " Update " +
                                        entry.date,
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: "/sites/default/images/no-image.jpg",
                                      alt:
                                        _vm.memberName +
                                        "'s " +
                                        _vm.category.name +
                                        " Update " +
                                        entry.date,
                                    },
                                  }),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }