<template>
    
    <section class="beforeAfter" :class="cssClass">

        <div class="wrapper">
            
            <h2 
                    class="beforeAfter__title" 
                    v-html="sectionTitle" 
                    v-if="sectionTitle.length"
            ></h2>
            
            <nav class="beforeAfter__nav">
    
                <ul class="beforeAfter__navList">
                    
                    <li 
                            v-for="(block, index) in blocks" 
                            :key="'imageNav' + index"
                    >
                        
                        <a
                                :href="'beforeAfter_' + (index + 1)"
                                :title="'View before and after Example #' + (index + 1)"
                                :class="[block.visible ? 'active' : '']"
                                @click.prevent="setVisible(index)"
                        >
                            Example #<span v-html="(index + 1)"></span>
                        </a>
                        
                    </li>
                    
                </ul>
    
            </nav> <!-- .beforeAfterSection__exampleNav -->
    
            <div class="beforeAfter__sliders">
    
    
                <div 
                        class="beforeAfterWrap" 
                        :id="'beforeAfter_' + (index + 1)" 
                        v-for="(block, index) in blocks" 
                        :key="'imageSlide' + index"
                        v-if="block.visible"
                >
                    
                    
                    <div class="beforeAfterWrap__beforeContent">
    
                        <div class="beforeAfterWrap__innerContent">
    
                          
                            <h3 
                                    class="beforeAfterWrap__title" 
                                    v-html="block['Before Title']['value']"
                                    v-if="block['Before Title']['value'].length"
                            ></h3>
                            
                            <div 
                                    class="beforeAfterWrap__content wysiwyg" 
                                    v-html="block['Before Content']['value']"
                                    v-if="block['Before Content']['value']"
                            ></div> <!-- .beforeAfterWrap__content wysiwyg -->
    
                        </div> <!-- .beforeAfterWrap__innerContent -->
    
                    </div> <!-- .beforeAfterWrap__beforeContent -->
                    
                    
                    <div class="beforeAfterImages">
    
                        <div class="beforeAfterImages__container" :style="blockPositionCSS(blocks[index].position)">
    
                            <div class="beforeAfterImages__images">
    
                                <img
                                        class="imageBefore sliderImage"
                                        :src="imageFolder + block['Before Image']['value']"
                                        :alt="block['Before Image']['Alt']"
                                        width="882"
                                        height="666"
                                />
    
                                <img
                                        class="imageAfter sliderImage"
                                        :src="imageFolder + block['After Image']['value']"
                                        :alt="block['After Image']['Alt']"
                                        width="882"
                                        height="666"
                                />
    
                            </div>
    
                            <input
                                    type="range"
                                    min="0"
                                    max="100"
                                    v-model="blocks[index].position"
                                    aria-label="Percentage of before image to shown"
                                    class="beforeAfterImages__slider"
                            />
    
                            <div class="beforeAfterImages__line" aria-hidden="true"></div>
    
                            <div class="beforeAfterImages__button" aria-hidden="true">
    
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="16" viewBox="0 0 27 16" fill="none">
                                    <g>
                                        <path 
                                                d="M25.9789 9.12557L19.5753 15.5292C19.125 15.9794 18.4246 16.1295 17.8243 15.8794C17.224 15.6292 16.8237 15.0289 16.8237 14.3785V1.57132C16.8237 0.920952 17.224 0.370642 17.8243 0.120501C18.4246 -0.129639 19.125 0.020445 19.5753 0.470698L25.9789 6.8743C26.6292 7.47464 26.6292 8.52523 25.9789 9.12557Z" 
                                                fill="currentColor"/>
                                        <path 
                                                d="M1.45569 9.12557L7.85929 15.5292C8.30955 15.9794 9.00994 16.1295 9.61028 15.8794C10.2106 15.6292 10.6108 15.0289 10.6108 14.3785V1.57132C10.6108 0.920952 10.2106 0.370642 9.61028 0.120501C9.00994 -0.129639 8.30955 0.020445 7.85929 0.470698L1.45569 6.8743C0.805323 7.47464 0.805323 8.52523 1.45569 9.12557Z" 
                                                fill="currentColor"
                                        />
                                    </g>
                                </svg>
                                
                            </div>
    
                        </div><!-- .beforeAfterImages__container -->
    
                    </div> <!-- .beforeAfterImages -->
                    
                    
                    <div class="beforeAfterWrap__afterContent">
    
                        <div class="beforeAfterWrap__innerContent">
    
                            <h3 
                                    class="beforeAfterWrap__title" 
                                    v-html="block['After Title']['value']"
                                    v-if="block['After Title']['value'].length"
                            ></h3>
                            
                            <div 
                                    class="beforeAfterWrap__content wysiwyg" 
                                    v-html="block['After Content']['value']"
                                    v-if="block['After Content']['value'].length"
                            ></div> <!-- .beforeAfterWrap__content wysiwyg -->
    
    
                        </div> <!-- .beforeAfterWrap__innerContent -->
    
                    </div> <!-- .beforeAfterWrap__beforeContent -->
    
    
                </div> <!-- .beforeAfterWrap -->
                
                
            </div> <!-- .sliders -->

        </div> <!-- .wrapper -->
        
    </section> <!-- .beforeAfterSection -->

</template>

<script>

    export default {
        name: 'imagesBeforeAfter',
        props: {
            cssClass: {
                type: [String, Boolean],
                default: ''
            },
            sectionTitle: {
                type: [String, Boolean],
                default: ''
            },
            imageBlocks: {
                type: [Object, Array, Boolean],
                default: false
            },
            imageFolder: {
                type: [String, Boolean],
                default: ''
            }
        },
        data() {
            return {
                blocks: false,
                sliderPosition: 50
            }
        },
        created() {
            
            //Set initial blocks
            this.blocks = this.imageBlocks.map((block, index) => {
                
                block.position = this.sliderPosition;
                block.visible = false;
                
                if(index < 1) {
                    block.visible = true;
                }
                
                return block;
            });
            
        },
        methods: {
            
            blockPositionCSS(pos) {
                return '--position: ' + pos + '%';
            },
            
            setVisible(index) {
    
                this.blocks = this.blocks.map((block, i) => {
                    
                    if(index == i) {
                        block.visible = true;
                    }else{
                        block.visible = false;
                    }
                    
                    return block;
                    
                });
                
            }
            
        }
    }
    
</script>

<style lang="less">

    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';


    .beforeAfter {
        
        padding: 80px 0;
        min-height: 969px;
        
        @media all and (max-width: 900px) {
            min-height: 0;
        }
        
        &__title {
            margin: 0;
            text-align: center;
        }
        
        &__nav {
            padding-top: 40px;
        }

        &__navList {

            display: flex;
            gap: 45px;
            justify-content: center;

            @media all and (max-width: 800px) {
                gap: 24px;
            }


            a {
                .rems(24);
                font-weight: 700;
                line-height: normal;
                text-decoration-line: none;
                color: @primary_color;
                opacity: 0.2;
                transition: opacity 200ms linear;

                @media @mobile {
                    .rems(18);
                    line-height: 26px;
                }

                &.active {
                    text-decoration: underline;
                    opacity: 1 !important;
                    color: @primary_color !important;
                }

                &:hover, &:active, &:focus-visible {
                    opacity: 0.5;
                    color: @primary_color !important;
                    transition: opacity 200ms linear;
                }
                

            }

        }

        &__sliders {
            padding-top: 24px;
        }
        
        &.categoryBeforeAfter {
            
            padding: 0;
            min-height: 809px;
            
            @media all and (max-width: 850px) {
                padding: 40px 0 64px;
                min-height: 0;
            }
        }
        
        & + .serviceBanner {
            margin-top: -70px;
            
            @media all and (max-width: 850px) {
                margin-top: 0;
            }
        }
        
    }

    .beforeAfterWrap {

        position: relative;

        &__beforeContent, &__afterContent {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50%;
            z-index: 4;
            //pointer-events: none;
            text-align: left;
            display: flex;
            justify-content: center;

            @media all and (max-width: 850px) {
                position: relative;
                left: auto;
                right: auto;
                transform: none;
                width: 100%;
                text-align: center;
                display: block;
            }

        }

        &__beforeContent {
            left: 0;
            padding-right: 10%;

            @media all and (max-width: 1100px) {
                padding-right: 25%;
            }

            @media all and (max-width: 850px) {
                padding: 0 0 24px 0;
            }
        }

        &__afterContent {
            right: 0;
            padding-left: 10%;

            @media all and (max-width: 1100px) {
                padding-left: 25%;
            }

            @media all and (max-width: 850px) {
                padding: 24px 0 0 0;
            }

            .beforeAfterWrap__title {
                color: @highlight_color;
            }
        }

        &__innerContent {
            width: 100%;
            max-width: 340px;

            @media all and (max-width: 850px) {
                max-width: 100%;
            }
        }

        &__title {
            .rems(24);
            font-weight: 500;
            line-height: 1em;
            color: #939393;
            margin: 0;

            @media all and (max-width: 1100px) {
                .rems(20);
            }

            @media all and (max-width: 850px) {
                .rems(24);
                line-height: 32px;
            }
        }

        &__content {

            color: #505D68;
            margin: 0;
            padding: 12px 0 0 0;
            .rems(20);
            font-weight: 500;
            line-height: 27px;
            letter-spacing: normal;

            @media all and (max-width: 1100px) {
                .rems(18);
                line-height: 26px;
            }

            @media all and (max-width: 850px) {
                .rems(20);
                line-height: 27px;
            }
        }
        
        .beforeAfterImages {

            pointer-events: auto;

            &__container {
                display: grid;
                place-content: center;
                position: relative;
                //overflow: hidden;
                --position: 50%;
                width: 100%;
                max-width: 882px;
                margin: 0 auto;
            }

            img {
                display: block;
                max-width: none;
                max-height: none;
                width: 100%;
                height: auto;

                &.sliderImage {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: left;
                }

                &.imageBefore {
                    position: absolute;
                    inset: 0;
                    width: var(--position);
                    background: #fff;
                }
            }

            &__images {
                width: 100%;
                max-width: 100%;
            }

            &__slider {
                position: absolute;
                inset: 0;
                cursor: pointer;
                opacity: 0;
                /* for Firefox */
                width: 100%;
                height: 100%;
                z-index: 8;

                &:focus-visible ~ .beforeAfterImages__button {
                    outline: 1px solid @primary_color;
                    outline-offset: 1px;
                }

                &:hover ~ .beforeAfterImages__button, &:active ~ .beforeAfterImages__button {
                    color: @primary_color;
                    transition: color 200ms linear;
                }
            }

            &__line {
                position: absolute;
                inset: 0;
                width: 6px;
                height: 100%;
                /* z-index: 10; */
                left: var(--position);
                transform: translateX(-50%);
                //pointer-events: none;
                background: #fff;
            }

            &__button {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                color: rgba(0, 0, 0, 0.3);
                padding: 0;
                border-radius: 50%;
                width: 42px;
                height: 42px;
                top: 50%;
                left: var(--position);
                transform: translate(-50%, -50%);
                //pointer-events: none;
                /* z-index: 100; */
                box-shadow: 0 8px 8px rgba(0, 0, 0, 0.4);
                background: #fff;
                transition: color 200ms linear;
                margin-top: -10%;

            }

        }

    }


</style>
