<template>

    <li
            class="menus"
            :class="[(megaMenu && !isMobile) ? 'megaMenuItem' : 'menuItem', parentMenu.subs.length ? 'hasChildren' : '', parentMenu.css_class]"
            v-if="parentMenu.published"
            @mouseleave="onMouseLeave"
            @mouseenter="onMouseEnter"

    >

        <a
                :href="parentMenu.url"
                :target="parentMenu.target"
                class="menuItemTop"
                :class="[megaMenuActive ? 'menuTopItemActive' : '']"
                @click="toggleMegaMenu"
                :data-id="parentMenu.id"
        >
            {{ parentMenu.name }}


        </a>


        <div class="dropdownWrap level1" v-if="menuItems?.length" :class="[megaMenu ? 'flyoutOnly' : '']">

            <ul>

                <template v-for="menuListItem in menuItems">

                    <li :class="[menuListItem.css_class, menuListItem.subs?.length ? 'hasChildren' : '']">
                        <a
                                :href="menuListItem.url"
                                :target="menuListItem.target"
                                :data-id="menuListItem.id"
                        >
                            {{ menuListItem.name }}

                        </a>

                        <div class="dropdownWrap level2" v-if="menuListItem.subs?.length">

                            <ul>

                                <template v-for="menuListItem2 in menuListItem.subs">

                                    <li :class="[menuListItem2.css_class, menuListItem2.subs?.length ? 'hasChildren' : '']">

                                        <a
                                                :href="menuListItem2.url"
                                                :target="menuListItem2.target"
                                                :data-id="menuListItem2.id"
                                        >

                                            {{ menuListItem2.name }}

                                        </a>

                                        <div class="dropdownWrap level3" v-if="menuListItem2.subs?.length">

                                            <ul>

                                                <template v-for="menuListItem3 in menuListItem2.subs">

                                                    <li :class="[menuListItem3.css_class]">
                                                        <a
                                                                :href="menuListItem3.target"
                                                                :target="menuListItem3.target"
                                                                v-html="menuListItem3.name"
                                                                :data-id="menuListItem3.id"
                                                        >
                                                        </a>
                                                    </li>

                                                </template>

                                            </ul>

                                        </div> <!-- .subMenu -->

                                    </li>

                                </template>

                            </ul>

                        </div> <!-- .subMenu -->

                    </li>

                </template>

            </ul>

        </div> <!-- .subMenu -->

        <div class="megaMenuWrapper" v-if="!isMobile && megaMenu && menuItems?.length" :class="[megaMenuActive ? 'megaMenuVisible' : '']">

            <div class="wrapper">

                <div class="megaMenuGrid">

                    <div class="megaMenuLeft">

                        <div class="megaMenuInfo">

                            <h2
                                    class="menuInfoTitle"
                                    v-html="parentMenu.name"
                            ></h2>


                            <div class="menuInfoButtonWrap">

                                <a :href="parentMenu.url" class="button">
                                    View All
                                </a>

                            </div> <!-- .menuInfoButtonWrap -->

                            <div class="menuInfoBackdrop">

                                <svg width="335" height="254" viewBox="0 0 335 254" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M344.656 0.646077C344.656 0.646077 183.298 36.4947 146.59 154.55C132.825 198.84 144.684 240.968 172.952 267.569C176.618 270.999 183.107 270.426 185.199 265.892C223.714 182.461 281.733 94.175 313.767 61.7162C314.76 62.338 282.068 104.322 248.742 171.469C233.179 202.861 215.588 243.688 204.586 276.198C203.015 280.874 204.393 285.908 209.072 287.578C238.374 298.131 335.058 317.018 359.918 180.409C378.64 106.191 344.649 0.648834 344.649 0.648834L344.656 0.646077Z" fill="#F5F5F5"/>
                                    <path d="M-28.1819 138.621C-30.4681 181.418 -28.3139 224.681 -2.25882 261.099C19.0508 290.867 65.393 309.644 94.913 291.537C99.5029 288.745 99.1874 284.034 97.5881 278.907C83.1576 232.857 39.2943 204.454 25.8233 189.049C26.556 188.409 56.7744 203.258 110.402 266.793C114.101 271.194 121.441 268.767 123.384 263.364C130.416 243.923 125.916 217.206 112.462 196.32C85.331 154.403 26.5576 136.657 -28.1819 138.621Z" fill="#F5F5F5"/>
                                </svg>


                            </div> <!-- .megaMenuInfo -->

                        </div> <!-- .megaMenuInfo -->

                    </div> <!-- .megaMenuLeft -->

                    <div class="megaMenuMenus">

                        <ul class="menuList">

                            <li v-for="menuItem in menuItems">
                                <a
                                        :href="menuItem.url"
                                        :target="menuItem.target"
                                        :data-id="menuItem.id"
                                >
                                    <i class="menuIcon">

                                        <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 256 512"
                                        >
                                            <path
                                                    d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"
                                                    fill="currentColor"
                                            />
                                        </svg>

                                    </i>

                                    {{ menuItem.name }}

                                </a>

                                <ul v-if="menuItem.subs?.length">
                                    <li v-for="subMenuItem in menuItem.subs">
                                        <a
                                            v-if="subMenuItem.name !== 'View all'"
                                            :href="subMenuItem.url"
                                            :target="subMenuItem.target"
                                            v-html="subMenuItem.name"
                                            :data-id="subMenuItem.id"
                                        >
                                        </a>
                                    </li>
                                </ul>
                            </li>

                        </ul>


                    </div> <!-- .megaMenuMenus -->

                    <!-- client feedback
                    <div class="megaMenuRight">

                        <div class="imageWrap">

                            <img
                                    src="/sites/default/images/examples/menu-example.png"
                                    alt="Static alt"
                                    width="318"
                                    height="520"
                            />

                        </div> --> <!-- .imageWrap -->

                    </div> <!-- .megaMenuRight -->

                </div> <!-- .megaMenuGrid -->


            </div> <!-- .wrapper -->

        </div> <!-- .megaMenu -->


    </li> <!-- .menuContainer -->

</template>

<script>

import _ from 'lodash';

export default {

    name: 'mtcMenu',
    props: {
        parentMenu: {
            type: [Object, Array],
            default (rawProps) {
                return [];
            }
        },
        cssClass: {
            type: [String],
            default (rawProps) {
                return '';
            }
        },
    },
    data() {
        return {
            menuItems: [],
            megaMenu: false,
            menuColumns: [],
            megaMenuActive: false,
            activeMenus: [],
            isMobile: false,
            mobileBreakpoint: 1120,
            maxColumnLength: 10,
            closingMenu: false,
            menuCloseTimeout: null
        }
    },
    created() {

        const _this = this;

        if(this.parentMenu.subs.length) {
            this.menuItems = this.parentMenu.subs;
        }

        //console.log(his.megaMenu);
        if(this.cssClass?.length) {
            const megaRegEx = new RegExp('.*?megaMenu.*?', 'i');
            this.megaMenu = megaRegEx.test(this.cssClass) > -1;
            this.menuColumns = this.buildMenuColumns();
            this.showSubMenu(0,0);
        }

        if(this.parentMenu.subs.length) {

            //Remove "shop" from sub items
            if(this.megaMenu && this.menuItems.length) {
                this.menuItems = this.menuItems.filter(item => {
                    if (item.name.trim().toLowerCase() !== 'shop') {
                        return item;
                    }
                });
            }
        }


    },
    mounted() {

        const _this = this;

        if(window.innerWidth <= this.mobileBreakpoint) {
            this.isMobile = true;
        }else{
            this.isMobile = false;
        }

        window.addEventListener('resize', () => {

            if(window.innerWidth <= _this.mobileBreakpoint) {
                _this.isMobile = true;
            }else{
                _this.isMobile = false;
            }

        });
    },
    methods: {

        buildMenuColumns() {

            let columns1 = [],
                columns2 = [],
                columns3 = [],
                columns4 = [];

            columns1.push({
                id: 0,
                parentID: 0,
                published: 1,
                visible: true,
                menus: this.addParentLinks(false, [...[], ...this.menuItems])
            });

            this.menuItems.forEach((menuItem1, index) => {


                if(menuItem1.subs?.length) {

                    columns2.push({
                        id: parseInt(menuItem1.id),
                        parentID: parseInt(menuItem1.parent_id),
                        published: parseInt(menuItem1.published),
                        visible: false,
                        menus: this.addParentLinks(menuItem1, menuItem1.subs)
                    });

                    menuItem1.subs.forEach(menuItem2 => {

                        if(menuItem2.subs?.length) {

                            columns3.push({
                                id: parseInt(menuItem2.id),
                                parentID: parseInt(menuItem2.parent_id),
                                published: parseInt(menuItem2.published),
                                visible: false,
                                menus: this.addParentLinks(menuItem2, menuItem2.subs)
                            });

                            menuItem2.subs.forEach(menuItem3 => {

                                if(menuItem3.subs?.length) {
                                    columns4.push({
                                        id: parseInt(menuItem3.id),
                                        parentID: parseInt(menuItem3.parent_id),
                                        published: parseInt(menuItem3.published),
                                        visible: false,
                                        menus: this.addParentLinks(menuItem3, menuItem3.subs)
                                    });
                                }

                            });

                        }

                    });

                }

            });

            return [
                    columns1,
                    columns2,
                    columns3,
                    columns4
            ]

        },

        isParentMoreLink(menuItem) {

           return !_.isUndefined(menuItem.isParentLink);

        },

        addParentLinks(menuItem, subs) {


            if(menuItem) {

                let title = 'View ' + menuItem.name;

                let catIDNum = parseInt(menuItem.category_id),
                        catID = !isNaN(catIDNum) ? catIDNum : 0;

                let catList = 0,
                    catListID = 0;

                if(!_.isUndefined(menuItem.category_list)) {
                    catList = parseInt(menuItem.category_list);
                    catListID = !isNaN(catList) ? catList : 0;
                }

                if(catID > 0 || catListID > 0) {
                    title = 'View all';
                }


                let parentLink = {

                    id: -2,
                    parent_id: 0,
                    published: 1,
                    subs: [],
                    target: '_self',
                    name: title,
                    url: menuItem.url,
                    isParentLink: true

                };

                subs.push(parentLink);

            }

            return subs;
        },

        showSubMenu(currentColumnIndex, parentID) {

            this.activeMenus[currentColumnIndex] = parentID;

            for(const colIndex in this.activeMenus) {
                if(colIndex > currentColumnIndex) {
                    this.activeMenus[colIndex] = 0;
                }
            }

            const nextIndex = currentColumnIndex + 1;

            if(!_.isUndefined(this.menuColumns[nextIndex])) {

                for(const menuIndex in this.menuColumns[nextIndex]) {

                    //Clear sub menus
                    for(const allMenuIndex in this.menuColumns) {
                        if(allMenuIndex > nextIndex) {
                            for(const i in this.menuColumns[allMenuIndex]) {
                                this.menuColumns[allMenuIndex][i].visible = false;
                            }
                        }
                    }

                    //Mark current as visible
                    if( this.menuColumns[nextIndex][menuIndex].id == parentID ) {
                        this.menuColumns[nextIndex][menuIndex].visible = true;
                    }else{
                        this.menuColumns[nextIndex][menuIndex].visible = false;
                    }


                }

            }


        },

        hasVisibleMenus(columnIndex) {

            const hasVisible = this.menuColumns[columnIndex].filter(menu => menu.visible);
            return hasVisible.length;

        },

        toggleMegaMenu(e) {

            if(this.megaMenu && !this.isMobile) {
                e.preventDefault();
                this.megaMenuActive = !this.megaMenuActive;
            }

        },

        onMouseLeave() {
            if(this.megaMenu && !this.isMobile) {

                this.closingMenu = true;

                clearTimeout(this.menuCloseTimeout);
                this.menuCloseTimeout = setTimeout(() => {

                    if(this.closingMenu) {
                        this.megaMenuActive = false;
                    }

                }, 1000);
            }
        },

        onMouseEnter() {

            if(this.megaMenu && !this.isMobile) {
                clearTimeout(this.menuCloseTimeout);
                this.closingMenu = false;
            }
        }


    }

}

</script>

<style lang="less">
    @import '@site/css/variables.less';
    @import '@core/css/mixins.less';
    @import '@site/../default/css/base/mixins.less';

    .headerInnerWrap {

        .mainMenu {

            li {
                &:hover {
                    & > .dropdownWrap {
                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transition: opacity 200ms linear, visibility 200ms linear;
                    }
                }

                &.mobileOnly {
                    display: block !important;
                }
            }
        }

        .menus {

            font-size: inherit;
            line-height: inherit;
            font-weight: 500;

            &.menuItem {
                position: relative;
            }

            .dropdownWrap {

                a {
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: inherit;
                    color: @flyout_link_color;
                    white-space: nowrap;


                    @media @flyout {
                        color: @flyout_link_color_mobile;
                    }

                    &:hover, &:active, &:focus, &.active {
                        color: @highlight_color;
                        transition: color 200ms linear;
                    }


                }
            }

            @media @above_flyout {


                .menuTopItemActive {
                    color: @highlight_color;

                    &:hover, &:focus, &:active {
                        color: darken(@highlight_color, 8%);
                    }
                }

                .dropdownWrap {
                    position: absolute;
                    border: 1.5px solid @secondary_color;
                    padding: 10px 0;
                    border-radius: 10px;
                    background: #fff;
                    min-width: 180px;

                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 200ms linear, visibility 200ms linear;

                    a {
                        padding: 0.7em 16px;
                        display: flex;
                        align-items: center;
                    }


                    &.level1 {
                        top: 100%;
                        left: 0;
                        margin-top: 16px;

                        &:before {
                            content: "";
                            display: block;
                            width: 100%;
                            height: 16px;
                            position: absolute;
                            right: 0;
                            left: 0;
                            bottom: 100%;
                        }

                        &.flyoutOnly {
                            display: none;
                        }

                    }

                    &.level2 {
                        top: -1.5px;
                        left: 100%;
                    }

                    &.level1, &.level2 {
                        .menuIcon {
                            width: 8px;
                            height: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 7px;
                            transform: rotate(-90deg);

                            svg {
                                width: 100%;
                                height: auto;
                            }

                        }
                    }

                    &.level3 {
                        top: -3px;
                        left: 100%;
                    }

                    &.level2, &.level3 {

                        margin-left: 8px;

                        &:before {
                            content: "";
                            display: block;
                            width: 8px;
                            height: 100%;
                            position: absolute;
                            right: 100%;
                            top: 0;
                        }
                    }
                }

                .megaMenuWrapper {
                    display: flex;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    height: auto;
                    min-height: 0;
                    width: 100%;
                    max-width: 1299px;
                    background: transparent;
                    padding: 0;
                    margin: 0;
                    border-radius: 0 0 27px 27px;
                    box-shadow: 0 11px 24px 0 rgba(0, 0, 0, 0.10), 0 43px 43px 0 rgba(0, 0, 0, 0.09), 0 98px 59px 0 rgba(0, 0, 0, 0.05), 0 174px 69px 0 rgba(0, 0, 0, 0.01), 0 271px 76px 0 rgba(0, 0, 0, 0.00);
                    overflow: hidden;

                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                    transition: opacity 300ms, visibility 300ms;

                    &.megaMenuVisible {

                        opacity: 1;
                        visibility: visible;
                        pointer-events: auto;
                        transition: opacity 300ms, visibility 300ms;

                    }


                    .wrapper {
                        width: 100%;
                        display: flex;
                        height: 100%;
                        padding: 0 !important;
                        z-index: 2;

                        &:before, &:after {
                            display: none;
                        }
                    }


                    .menuColumn {
                        height: 100%;
                        border-right: 1.5px solid @secondary_color;

                        &:first-child {
                            padding-left: 0;

                            .menuList {
                                a {
                                    padding-left: 0;
                                }
                            }
                        }

                        &:last-child {
                            border-right: 1.5px solid transparent;
                        }
                    }

                    .menuList {

                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        //max-height: 520px;
                        margin: 0;
                        padding: 0 0 0 16px;
                        float: none;
                        clear: both;
                        flex: 0 0 100%;
                        height: auto;
                        width: ~"calc(100% + 32px)";
                        margin: 0 -16px;

                        &:before, &:after {
                            display: none;
                        }

                       > li > a {
                            padding: 0.6em 16px 0.6em 20px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            .rems(24);
                            font-weight: 500;
                            line-height: 25.278px;
                            color: @primary_color;


                            &:hover, &:active, &:focus-visible, .active {
                                color: @highlight_color !important;
                                transition: color 200ms linear;

                                svg {
                                    color: @highlight_color !important;
                                    transition: color 200ms linear;
                                }
                            }
                        }

                        > li {
                            list-style: none;
                            width: 33.33333333%;

                            ul {
                                padding-left: 20px;
                                padding-bottom: 10px;

                                a {
                                    padding: 4px 0;
                                }
                            }
                        }

                        .menuIcon {
                            position: absolute;
                            top: 13px;
                            left: 0;
                            margin: 0;
                            color: inherit;
                            display: inline-block !important;
                            width: 12px;
                            height: 26px;


                            svg {
                                width: 100%;
                                height: 100%;
                                color: @primary_color;
                                transition: color 200ms linear;

                                path {
                                    fill: currentColor;
                                }
                            }
                        }

                    }
                }

                &.megaMenuItem {
                    & > a {
                        position: relative;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 100%;
                            left: -24px;
                            right: -24px;
                            height: 42px;
                            width: ~"calc(100% + 48px)";
                            background: transparent;
                        }

                    }
                }

            }

            @media all and (max-width: (@wrapper_width + @page_side_padding)) {
                .megaMenuWrapper {
                    .wrapper {
                        padding: 0 !important;
                    }
                }
            }

            @media @flyout {

                .dropdownWrap {

                    padding: 8px 0 8px 8px;

                    &.level1 {
                        &.flyoutOnly {
                            display: block;

                        }
                    }


                }

                .megaMenuWrapper {
                    display: none;
                }

            }


            &.hasChildren {

                .menuItemTop {

                    &:after {

                        @media @flyout {
                            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="%23fff"/></svg>');
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            position: relative;
                            top: 3px;
                            left: 6px;
                            transform: rotate(0deg);
                            transition: transform 200ms linear;
                            transform-origin: 5px 17px;
                        }
                    }

                    &.open {
                        &:after {
                            @media @flyout {
                                transform: rotate(-90deg);
                                transition: transform 200ms linear;
                                transform-origin: 5px 17px;
                            }
                        }
                    }

                }

            }

        }

        .megaMenuGrid {

            display: flex;
            width: 100%;

            .megaMenuLeft {
                display: flex;
                flex: 0 0 25.6356%;
            }

            .megaMenuRight {

                display: flex;
                flex: 0 0 24.7119%;
                overflow: hidden;

                .imageWrap {
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    background: #fff;
                    overflow: hidden;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        margin-left: 1px;
                    }

                }

            }

            .megaMenuMenus{

                background: #fff;
                flex: 1 1 100%;
                padding: 48px 50px 37px 50px;
                min-height: 320px;

            }

        }

        .megaMenuInfo {

            background: @primary_color;
            width: 100%;
            height: auto;
            padding: 57px 32px;
            position: relative;
            color: #fff;


            .menuInfoTitle {
                color: inherit;
                .rems(32);
                font-weight: 500;
                line-height: 39.308px;
                letter-spacing: -0.244px;
                margin: 0;
                position: relative;
                z-index: 2;
            }

            p {
                color: inherit;
                padding: 14px 0 0;
                margin: 0;
                position: relative;
                z-index: 2;
                .rems(18);
                font-weight: 400;
                line-height: 19px;
                opacity: 0.8;
            }

            .menuInfoButtonWrap {

                position: relative;
                z-index: 2;
                padding: 49px 0 0;

                .button {

                    .rems(19);
                    padding: 16.1px 22.3px;
                    margin: 0;
                    width: auto;
                    color: @primary_color;
                    background: rgba(255,255,255,1);
                    transition: background 200ms linear;
                    border: none !important;

                    &:after {
                        background: #fff;
                    }

                    &:hover, &:focus-visible, &:active  {
                        background: rgba(255,255,255,0.8);
                        transition: background 200ms linear;
                    }
                }
            }

            .menuInfoBackdrop {

                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1;
                opacity: 0.1;

                svg {
                    width: 100%;
                    height: auto;
                    margin-bottom: -7px;
                }
            }

        }

    }

    .megaMenuAlt {

        .megaMenuInfo {
            background: #B1AD86 !important;
        }

        .menuInfoButtonWrap {

            .button {
                color: #B1AD86 !important;
            }

        }

    }
</style>
